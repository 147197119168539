import { encode, decode } from 'js-base64';
// import {getRandomStr, insertStr} from './string'
const salt = 'effe202404?!effe'
const saltBits = [1,2,3,4]
const saltEncode = (str:string) =>{
    let result = encode(str+salt);
    // console.log("salt**encode**",result)
    // const resultLength = result.length;
    //todo encode应该在特殊位置加混淆字符,decode应该在特殊位置去除混淆字符
    // for (let index = 0; index < saltBits.length; index++) {
    //     const saltNumber = saltBits[index]+index;
    //     if(resultLength > saltNumber){
    //         insertStr(result,saltNumber,getRandomStr(1) || 'a')
    //     }
    //     else{
    //         result+=getRandomStr(1) || 'a'
    //     }
    // }
    // console.log("salt**encode2**",result)
    return result;
}


const saltDecode = (str:string) =>{
    //todo encode应该在特殊位置加混淆字符,decode应该在特殊位置去除混淆字符
    const decodeStr = decode(str)
    if(decodeStr.endsWith(salt))
    return decodeStr.slice(0,decodeStr.length-salt.length)
    else return undefined;
}
export{
    saltEncode as encode,
    saltDecode as decode,
}