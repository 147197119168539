import { combineReducers } from 'redux';
// import * as authLocalData from '../presist/auth-local-data';
import * as userInfoLocalData from './userinfo-local-data';




const combinedReducer = combineReducers({
  userInfoLocalData: userInfoLocalData.reducer(),

  
});

const actions = {
  userInfoLocalData: userInfoLocalData.actions,

 
};

const selectors = {
  userInfoLocalData: userInfoLocalData.select,

};

export {
  actions,
  selectors,
  combinedReducer,
  userInfoLocalData,
  // authLocalData,

};
