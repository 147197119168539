export const HOMEPAGE = '/'
// export const DASHBOARD = '/dashboard'
// export const AIAPPS = '/ai-apps'
export const MAINPAGE = '/main'
export const PERSONA_DASHBOARD = '/main/dashboard'
export const AIAPPS = '/main/ai-portal'
export const REPUTATION_SCORE = '/main/reputationScore'
export const MYPROFILE = '/main/myProfile'

export const WELCOME = '/welcome'
export const SIGNIN = '/signin'
export const SIGNUP  = '/signup'
export const TASK = '/task'
export const PASSWORD_RESET = '/password/reset'
export const PASSWORD_FORGET = '/password/forget'
export const INVITE_PREFIX = '/invite/'
export const INVITE = '/invite/:code'

export const CLAIM_AIRDROP = '/claimAirdrop'


export const DASHBOARD = AIAPPS
