import React, { useEffect, useRef, useCallback, useState } from "react";

import { AxiosError } from "axios";
// import "../profiles-shared/services/api/app/app-client";
// import { setAuth, removeAuth, setRefreshInterceptor } from "../profiles-shared/services/api/app/app-client";

import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../redux/app-state";
import { AuthInfoData } from "../redux/presist/auth-local-data";
import { useLocation, useNavigate } from "react-router-dom";
import {
  removeAuth,
  setAuth,
  setRefreshInterceptor2,
} from "../api/server/request";
import {
  persistActions,
  persistSelectors,
  sharedActions,
  sharedSelectors,
} from "../redux";
import { userInfoLocalData } from "../redux/local";
import { domain } from "../utils/env";
import { decode, encode } from "../utils/saltBase64";
import {
  buildUserInfoCacheData,
  initialAuthInfoCacheData,
} from "../services/user";
import useUser from "./user-hook";
import { UserInfoLocalData } from "../redux/local/userinfo-local-data";
import { TOKEN_REFRESH_TIME, hasPlugin } from "../services/constants";
import { getUserInfo } from "../api/server/user/app";
import { UserInfoResponseData } from "../api/server/user/response";
import { authLocalData } from "../redux/presist";
import useLogin from "./login-hook";
import { TASK } from "../router/constants";
import * as routerConstant from "../router/constants";
import { useAutoConnect } from "./AutoConnectProvider";
import { ActionType, AuthMessageData } from "../services/plugin-message";
import * as _ from "lodash";
import { AirdropInfoResponseData } from "../api/server/airdrop/response";
import { getAirdropInfoWithAddress } from "../api/server/airdrop/app";

declare const window: any;

type TokenResponse = {
  accessToken: string | null;
  refreshToken: string | null;
};

const useUserInfo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const tokenRefreshTimer = useRef<number>();

  //设置bear token
  // useEffect(() => {
  //   initApp();
  // }, []);

  const initApp = () => {
    // setRefreshInterceptor2(refreshTokens);
  };

  const authInfoCacheData = useSelector<AppState, authLocalData.Selectors>(
    ({ persist }) =>
      persistSelectors.local.authLocalData(persist.local.authLocalData, {})
  );

  const updateAuthInfoCacheData = (data: AuthInfoData) =>
    dispatch(persistActions.local.authLocalData.update(data));

  const resetAuthInfoCacheData = () =>
    dispatch(persistActions.local.authLocalData.reset());

  // const useSsrLocalStorage = (
  //   key: string,
  //   initial: string |undefined
  // ): [string, React.Dispatch<string>] => {
  //   return typeof window === 'undefined'
  //     ? [initial, (value: string) => undefined]
  //     : useLocalStorage(key, initial);
  // };

  // const [authLocalData, setAuthLocalData, initialized, clear, prefixedKey] =
  // useLocalStorage("Login.AuthInfo", "", {
  //     prefix: domain,
  //   });

  // const [authLocalData, setAuthLocalData] = useLocalStorage(
  //   "Login.AuthInfo",
  //   undefined
  // );
  // useEffect(() => {
  //   try {
  //     //从缓存加载用户auth信息
  //     if (!authLocalData) {
  //       //没有缓存信息，暂且不管，由router接手，待验证
  //       // navigate(REGISTER_SINGUP)
  //       // return;
  //     }
  //     // const temp ='eyJhY2Nlc3NUb2tlbiI6ImV5SjBlWEFpT2lKS1YxUWlMQ0poYkdjaU9pSklVelV4TWlKOS5leUpwYzNNaU9pSnBjM04xYzJWeUlpd2lZWFZrSWpvaVlYVmthV1Z1WTJVaUxDSjFjMlZ5WDJsa0lqb2lNVFUxTkRnd01ERTVNVFkwTnpRME5EazVNeUlzSW5KdmJHVmZZMjlrWlhNaU9pSWlMQ0p1YVdOclgyNWhiV1VpT2lJeE1URWlMQ0owYjJ0bGJsOTBlWEJsSWpvaVlXTmpaWE56WDNSdmEyVnVJaXdpY205c1pWOXBaSE1pT2lJaUxDSjFjMlZ5Ym1GdFpTSTZJbk15TUNJc0ltTnNhV1Z1ZEY5cFpDSTZJbXRmWTJ4cFpXNTBJaXdpWlhod0lqb3hOall4TWpnME1Ua3dMQ0p1WW1ZaU9qRTJOakV5TmpJMU9UQjkuMF9PeGpYaUpFb0ZDa0lxcWRsRVMyRDdtZmpJMzk0SGtEVFNUcFY4bGZPNnVnakRvV3AzdS1Kb1B2MHI5OXZyQlFyZkg1M2Q4UDlkNkYyNFB6bnhoYVEiLCJ0b2tlblR5cGUiOiJiZWFyZXIiLCJyZWZyZXNoVG9rZW4iOiJleUowZVhBaU9pSktWMVFpTENKaGJHY2lPaUpJVXpVeE1pSjkuZXlKcGMzTWlPaUpwYzNOMWMyVnlJaXdpWVhWa0lqb2lZWFZrYVdWdVkyVWlMQ0oxYzJWeVgybGtJam9pTVRVMU5EZ3dNREU1TVRZME56UTBORGs1TXlJc0luUnZhMlZ1WDNSNWNHVWlPaUp5WldaeVpYTm9YM1J2YTJWdUlpd2lZMnhwWlc1MFgybGtJam9pYTE5amJHbGxiblFpTENKbGVIQWlPakUyTmpFNE5qY3pPVEFzSW01aVppSTZNVFkyTVRJMk1qVTVNSDAuemtWTkcyRDg5WVNBVzJMd2pobXM2MVpYeEJwY3lVbU4yOTdFZWNBc3hUZktqZVR5Tk9NLWxRNXJwNjFJbjdWalZVd3luMDFaZUxpd0h1TU50TllMWEEiLCJ1c2VySWQiOiIxNTU0ODAwMTkxNjQ3NDQ0OTkzIiwiYnVzaW5lc3NJZCI6bnVsbCwib2F1dGhJZCI6IiIsImF2YXRhciI6Imh0dHBzOi8vaW5mcmEuZXV0ZXJwZS5uZXQvc3RhdGljL2F2YXRhci80N2Y4OTMzZDk1NjE3Yjg3NzdiZDdjNWRkZjE2ZGY5Nl8yMjA4MTcwNTQ5MDU2LmpwZyIsImF1dGhvcml0eSI6IiIsInVzZXJuYW1lIjoiczIwIiwibmlja05hbWUiOiIxMTEiLCJleHBpcmVzSW4iOjIxNjAwfWJjZTIwMjIwNz8hYmNl'
  //     // const decodeStr = decode(temp)//decode(authLocalData);
  //     const decodeStr = decode(authLocalData ||'');
  //     // console.log("u3**authLocalData**decodeStr**", decodeStr)
  //     //没有缓存信息或者缓存信息有误，直接返回
  //     if (!decodeStr) {
  //       if (authInfoCacheData.getData?.canAuth !== false)
  //         updateAuthInfoCacheData({
  //           ...initialAuthInfoCacheData,
  //           canAuth: false,
  //         });
  //       return;
  //     }
  //     const decodeJsonData: AuthInfoData = JSON.parse(decodeStr);
  //     console.log("authLocalData**decodeJson**", decodeJsonData);
  //     //accesstoken不一致时才将缓存信息更新到内存
  //     if (decodeJsonData.accessToken != authInfoCacheData.getData?.accessToken)
  //       updateAuthInfoCacheData(decodeJsonData);
  //   } catch (error) {
  //     console.error(error);
  //     updateAuthInfoCacheData({ ...initialAuthInfoCacheData, canAuth: false });
  //   }

  //   return () => {};
  // }, [authLocalData]);

  //监听AuthData，确认用户登录后获取info信息
  useEffect(() => {
    console.log("authInfoCacheData.getData**", authInfoCacheData.getData);
    // if (!authInfoCacheData.getData.accessToken) return;
    //所有地方直接先缓存auth信息,authInfoCacheData根据缓存信息更新
    // //缓存auth信息
    // const jsonStr = JSON.stringify(authInfoCacheData.getData)
    // const encodeStr = encode(jsonStr);
    // if (authLocalData != encodeStr) setAuthLocalData(encodeStr)

    const isAuth =
      authInfoCacheData?.getData &&
      authInfoCacheData.getData.canAuth === true &&
      !!authInfoCacheData.getData.accessToken;
    const notAuth =
      authInfoCacheData?.getData && authInfoCacheData.getData.canAuth === false;
    const access_token = authInfoCacheData.getData.accessToken;
    //设置请求是否带token
    if (isAuth) {
      setAuth(access_token || "");
      getInfo();
    }
    // else if(notAuth){
    //   // signOut()
    //   removeAuth();
    // }

    return () => {};
  }, [authInfoCacheData?.getData]);

  // useEffect(() => {
  //   console.log("authInfoCacheData.getData**", authInfoCacheData.getData);
  //   const notAuth =authInfoCacheData?.getData && authInfoCacheData?.getData?.canAuth === false && authInfoCacheData?.getData?.sender ==='effective-web'
  //   //自己登出
  //  if(notAuth){
  //     signOut()
  //   }

  //   return () => {};
  // }, [authInfoCacheData?.getData?.canAuth]);

  // const clearNavigate = useClearNavigate();

  const { onLogout } = useLogin();
  // const signOut = async (forceRedirect = false) => {
  //   // console.log("signout的auth信息***", authInfoCacheData.getData);
  //   // removeAuth();
  //   // setAuthLocalData("");
  //   // resetAuthInfoCacheData();
  //   // clearNavigate();
  //   // if (forceRedirect || window.location.href !== window.location.origin) {
  //   //   window.location.href = window.location.origin;
  //   // }
  //   const redirect =
  //     forceRedirect || window.location.href !== window.location.origin;
  //   onLogout(redirect);
  // };

  // const authInfoCacheDataRef = useRef(authInfoCacheData.getData)
  const [pluginAuthData, setPluginAuthData] = useState<AuthMessageData>();
  useEffect(() => {
    //插件auth数据和web的一致就不操作
    if (_.isEqual(pluginAuthData, authInfoCacheData.getData)) return;
    if (!!pluginAuthData) {
      updateAuthInfoCacheData(pluginAuthData);
    } else {
      console.error("plugin 传了空authdata");
    }

    // if (!pluginAuthData || !authInfoCacheData?.getData) return;

    // console.log(
    //   "web***pluginAuthData消息***pluginAuthData1**",
    //   pluginAuthData,
    //   pluginAuthData?.canAuth
    // );
    // console.log(
    //   "web***pluginAuthData消息***authInfoCacheData**",
    //   authInfoCacheData?.getData,
    //   authInfoCacheData?.getData?.canAuth
    // );
    // if (
    //   pluginAuthData.canAuth === false &&
    //   authInfoCacheData.getData.canAuth === true
    // ) {
    //   console.log("web***接收到了不能登录消息***", pluginAuthData);
    //   signOut(true);
    // } else if (pluginAuthData.canAuth === true) {
    //   // console.log("接收到了被分享的auth信息入库1***", pluginAuthData);
    //   updateAuthInfoCacheData(pluginAuthData)

    //   // const json = JSON.stringify(pluginAuthData);
    // //   const encodeStr = encode(jsonStr);
    // //  JSON.stringify(authInfoCacheData.getData) !== encodeStr &&  updateAuthInfoCacheData(JSON.parse(encodeStr)) ;//setAuthLocalData(encodeStr);
    //   // updateAuthInfoCacheData(pluginAuthData);
    // }
    return () => {};
    // }, [pluginAuthData,authInfoCacheData.getData])
  }, [pluginAuthData]);

  useEffect(() => {
    //监听/接收plugin发过来的auth信息
    window.addEventListener(
      "message",
      async (e: any) => {
        // console.log("我接收到了消息message0***", e.data)
        if (typeof e?.data === "string") {
          // console.log("我接收到了消息message***", e.data);
          try {
            const resp: AuthMessageData = JSON.parse(e.data);
            // console.error("web接收到了被分享的auth消息***", resp);
            //需要key区分生产环境和测试环境
            if (
              "canAuth" in resp &&
              resp?.sender === "effective-plugin" &&
              resp?.key === process.env.REACT_APP_DOMAIN_NAME
            ) {
              //只能设置变量，通过useeffect再处理，才能拿到真实的authInfoCacheData.getData的值
              console.error("web收到了可用的被分享的auth消息***", resp);
              if (resp.action === ActionType.Login) {
                setPluginAuthData(resp);
              } else if (resp.action === ActionType.Logout) {
                //如果页面已经登出了，没必要重复登出了，否则会不断刷网页
                if (authInfoCacheData?.getData?.canAuth === false) return;
                onLogout();
              } else {
                setPluginAuthData(resp);
              }
            }
          } catch (error) {
            // console.error("err-in-message消息**", error);
          } finally {
          }
        }
      },
      false
    );
    // // 注册一个自定事件
    // window.addEventListener('msgEvent', (e) => {
    //   // 接受数据
    //   console.log("webweb收到了消息",e);
    // });
    const cEvt = new CustomEvent("askAuthInfo", {
      detail: {
        data: "askAuthInfo",
        key: process.env.REACT_APP_DOMAIN_NAME,
        sender: "effective-web",
      },
    });
    // 触发事件
    window.dispatchEvent(cEvt);
    console.error("web发送自定义消息**需要获取用户信息");
    return () => {};
  }, []);

  // const refreshTokens = async (failedRequest: AxiosError) => {
  //   // console.log("window**", window.location.href, "**", window.location.origin)
  //   console.log("failedRequest**", failedRequest.config?.url);
  //   console.log("try refreshTokens");
  //   //token过期,无法更新
  //   if (failedRequest.config?.url === api.refreshUserToken) {
  //     // removeAuth();
  //     // resetAuthInfoCacheData();
  //     // setAuthLocalData("");
  //     signOut();
  //     console.log("token expired and reset");
  //     // navigate(REGISTER_LOGIN)
  //     return;
  //     console.error("token expired");
  //   }

  //   //获取不到最新的state
  //   let user = undefined;
  //   if (!!authInfoCacheData.getData.userId) user = authInfoCacheData.getData;
  //   else {
  //     const decodeStr = decode(authLocalData);
  //     // console.log("authLocalData**decodeStr**", decodeStr)
  //     //没有缓存信息或者缓存信息有误，直接返回
  //     if (!decodeStr) {
  //       // removeAuth();
  //       // setAuthLocalData("");
  //       signOut();
  //       console.error("No authInfoCacheData and authLocalData");
  //       return;
  //     }
  //     const decodeJsonData: AuthInfoData = JSON.parse(decodeStr);
  //     user = decodeJsonData;
  //   }

  //   // const user = authInfoCacheData.getData
  //   // console.log("refreshTokens**authInfoCacheData**",user)
  //   try {
  //     if (!user) {
  //       // removeAuth();
  //       // setAuthLocalData("");
  //       signOut();
  //       console.error("No authInfoCacheData");
  //       return;
  //     }
  //     const refreshToken = user?.refreshToken;
  //     if (!refreshToken) {
  //       // removeAuth();
  //       // setAuthLocalData("");
  //       signOut();
  //       console.error("No refresh token present");
  //       return;
  //     }

  //     /*
  //       Using the AUTH_CONFIG here fails as it does not return all the correct audiences,
  //       the hunch is that as we are sending audiences there is then a bug on what is returned.
  //       By not sending the audience we can avoid the "bug".
  //      */
  //     const response: ResponseData = (await refreshUserToken(refreshToken))
  //       .data;
  //     const responseData: LoginInfoData = response.data;
  //     console.log("refreshTokens**responseData**", responseData);
  //     // if (!responseData.accessToken || !responseData.refreshToken) {
  //     //   removeAuth();
  //     //   //console.error('No access or refresh token returned');
  //     // }
  //     if (!responseData.accessToken) {
  //       // removeAuth();
  //       // setAuthLocalData("");
  //       signOut();
  //       console.error("No access  returned");
  //       return;
  //     }

  //     // console.log("refreshTokens**failedRequest.request**",failedRequest)
  //     // Update the access tokens for the failed request that triggered refreshTokens
  //     // @ts-ignore
  //     failedRequest.config.headers.Authorization = `${responseData.accessToken}`;
  //     // @ts-ignore
  //     failedRequest.response.config.headers.Authorization = `${responseData.accessToken}`;
  //     // console.log("refreshTokens**failedRequest.request**fix**",failedRequest)

  //     //todo目前refreshtoken不会更新，看后续是否改进
  //     const newRefreshToken = !!responseData.refreshToken
  //       ? responseData.refreshToken
  //       : refreshToken;
  //     //更新authlocal信息
  //     const decodeStr = decode(authLocalData);
  //     // console.log("authLocalData**decodeStr**", decodeStr)
  //     //没有缓存信息或者缓存信息有误，直接返回
  //     if (!decodeStr) {
  //       console.error("No authLocalData");
  //       return;
  //     }
  //     const decodeJsonData: AuthInfoData = JSON.parse(decodeStr);
  //     decodeJsonData.accessToken = responseData.accessToken;
  //     decodeJsonData.refreshToken = newRefreshToken;
  //     const jsonStr = JSON.stringify(decodeJsonData);
  //     const encodeStr = encode(jsonStr);
  //     if (authLocalData !== encodeStr) setAuthLocalData(encodeStr);
  //     setAuth(responseData.accessToken || "");
  //     return Promise.resolve();
  //     // return {
  //     //   accessToken: responseData.accessToken ,
  //     //   refreshToken: newRefreshToken,
  //     // };
  //   } catch (e) {
  //     // ERROR: Empty refresh token / Refresh token failed
  //     console.log("refreshTokens**error**", e);
  //     // removeAuth();
  //     // setAuthLocalData("");
  //     signOut();
  //     // if (window.location.href != window.location.origin)
  //     //   window.location.href = window.location.origin;
  //     console.error("refresh token err ");
  //   }
  // };

  // const accountsData = useSelector<AppState, account.accounts.Selectors>(
  //   ({ shared }) =>
  //     sharedSelectors.account.accounts(shared.account.accounts, {})
  // );

  let didCancel = false;
  let didCallAccounts = false;
  // const ga4 = useGA4React();
  // console.log("ga4**userinfo",ga4)

  // //ga4设置用户
  // useEffect(() => {
  //   if (!authInfoCacheData.getData.userId || !ga4) return;
  //   gaSetUser(ga4, authInfoCacheData.getData.userId);
  //   gaPageView(ga4, location.pathname + location.search);

  //   return () => {};
  // }, [authInfoCacheData.getData.userId, ga4]);

  const userInfoCacheData = useSelector<AppState, userInfoLocalData.Selectors>(
    ({ shared }) =>
      sharedSelectors.local.userInfoLocalData(
        shared.local.userInfoLocalData,
        {}
      )
  );

  const updateData = (data: UserInfoLocalData) =>
    dispatch(sharedActions.local.userInfoLocalData.update(data));

  // //刷新用户的信息
  // useEffect(() => {
  //   if (
  //     !!userInfoCacheData.getData.musicToken &&
  //     !userInfoCacheData.getData.username
  //   ) {
  //     console.log("userInfoCacheData**", userInfoCacheData.getData);
  //     getInfo();
  //   }
  //   return () => {};
  // }, [userInfoCacheData.getData]);

  // const getAuthToken = () => {
  //   if (!authInfoCacheData.getData.userId) return;
  //   //音乐端登录
  //   console.log("try getMusicToken**");
  //   getMusicToken(authInfoCacheData.getData.userId, uuidv4())
  //     .then((data) => {
  //       const response = data.data;
  //       if (response.code == "500") {
  //         console.log("获取音乐token失败***返回500**", response.data);
  //         // location.reload();
  //         //目前不需要token
  //         setMusicAuth("123");
  //         updateData({ ...userInfoCacheData.getData, musicToken: "123" });
  //       }
  //       if (response.code == "200") {
  //         console.log("getMusicToken**", response.data);
  //         const authToken = response.data.access_token;
  //         setMusicAuth(authToken);
  //         updateData({ ...userInfoCacheData.getData, musicToken: authToken });
  //         // getInfo();
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("@@@@@@ failed @@ ", err);
  //     });
  // };

  const getInfo = async () => {
    try {
      if (!authInfoCacheData.getData.accessToken) return;
      const responseData = await getUserInfo();
      if (didCancel) return;
      if (responseData?.data.code !== 200) {
        onLogout();
      }
      const resData: UserInfoResponseData = responseData.data.user;
      console.log("getInfo**responseData", responseData, resData);

      //判断用户是否存在
      if (!!resData.userId) {
        const buildUserData = buildUserInfoCacheData(resData);
        console.log("getInfo**buildUserData**", buildUserData);
        updateData({ ...userInfoCacheData.getData, ...buildUserData });

        //获取手续费设定
        // getFeeSettingData();
        // //获取playerv2的数据
        // getVideoPlayerFeeSettingData();
        // //获取账户余额
        // getAccounts();
        ////mock获取player充能/升级费用
        // getPlayerFee();
      } else {
        //应该不会出现这种情况
        console.log("user id is null");
      }
    } catch (error) {}
  };

  // const getFeeSettingData = async () => {
  //   const result = await getFeeSetting();
  //   if (didCancel || !result?.data.success) return;
  //   const data: FeeSettingData = result.data.data;
  //   if (!data) return;
  //   const feeData = data;
  //   // console.log("fee**", feeData);
  //   updateFeeCacheData(feeData);
  // };

  // const updateAccountCacheData = (
  //   data: cacheShared.accountLocalData.AccountCacheData
  // ) => dispatch(sharedActions.cache.accountLocalData.update(data));

  // const getAccounts = async () => {
  //   const response = await getBalance();
  //   console.log("getAccounts**response**", response);
  //   if (didCancel) return;
  //   if (!response.data.success) return;
  //   const accountData: BalanceData = response.data.data;
  //   updateAccountCacheData({
  //     data: [
  //       { id: Ethereum.id, value: accountData.ethValue },
  //       { id: Euterpe.id, value: accountData.eutValue },
  //       { id: HOT.id, value: accountData.hotValue },
  //       { id: POT.id, value: accountData.potValue },
  //       { id: USDT.id, value: accountData.usdtValue },
  //     ],
  //   });
  // };

  // const videoPlayerFeeCacheData = useSelector<
  //   AppState,
  //   cacheShared.videoplayerFeeLocalData.Selectors
  // >(({ shared }) =>
  //   sharedSelectors.cache.videoplayerFeeLocalData(
  //     shared.cache.videoplayerFeeLocalData,
  //     {}
  //   )
  // );

  // const updateVideoPlayerFeeCacheData = (
  //   data: cacheShared.videoplayerFeeLocalData.VideoplayerFeeLocalData
  // ) => dispatch(sharedActions.cache.videoplayerFeeLocalData.update(data));

  // const getVideoPlayerFeeSettingData = async () => {
  //   const result = await getPlayerV2Config();
  //   if (didCancel || !result?.data.success) return;
  //   const data: PlayerV2ConfigResponseData = result.data.data;
  //   if (!data) return;
  //   const feeData = data;
  //   // console.log("fee**", feeData);
  //   updateVideoPlayerFeeCacheData(feeData);
  // };

  // const localSettingData = useSelector<
  //   AppState,
  //   cacheShared.settingLocalData.Selectors
  // >(({ shared }) =>
  //   sharedSelectors.cache.settingLocalData(shared.cache.settingLocalData, {})
  // );

  // const updateLocalSettingData = (
  //   data: cacheShared.settingLocalData.LocalSettingData
  // ) => dispatch(sharedActions.cache.settingLocalData.update(data));

  // //本地测试功能-控制用户钱包类型
  // useEffect(() => {
  //   if (
  //     !!localSettingData.getData.walletType &&
  //     !!userInfoCacheData.getData.uid
  //   ) {
  //     console.log(
  //       "localSettingData.getData.walletType**",
  //       localSettingData.getData.walletType
  //     );
  //     updateData({
  //       ...userInfoCacheData.getData,
  //       walletType: localSettingData.getData.walletType,
  //     });
  //   }
  //   return () => {};
  // }, [localSettingData.getData.walletType, userInfoCacheData.getData.uid]);

  // const replaceUserVideoPlayerCacheData = (
  //   data: cacheShared.userVideoPlayerLocalData.UserVideoPlayerLocalData
  // ) => dispatch(cacheShared.userVideoPlayerLocalData.actions.replace(data));

  // const { playerData, activePlayerData } = useActivePlayerInfo();

  // //获取video播放器信息
  // useEffect(() => {
  //   if (userInfoCacheData.getData.uid) {
  //     // console.log(
  //     //   "socialfi***MYREARD_WEEKLYHISTORY",
  //     //   location.pathname,
  //     //   MYREARD_WEEKLYHISTORY
  //     // );
  //     if (!playerData?.getData?.data) {
  //       getPlayerInfoV2().then((response) => {
  //         const responseData = response.data;
  //         const data: PlayerV2InfoData[] = responseData.data;
  //         // console.log( "socialfi***getPlayerInfoV2**", data);
  //         replaceUserVideoPlayerCacheData({ data: data });
  //       });
  //     }
  //   }

  //   return () => {};
  // }, [userInfoCacheData?.getData?.uid]);

  // return (
  // <div>
  // <LocalSetting></LocalSetting>
  // <WalletInfo></WalletInfo>
  // <ContractInfo></ContractInfo>
  // </div>
  // );

  // const {setAutoConnect}=useAutoConnect()
  // useEffect(() => {
  //   setAutoConnect(!!userInfoCacheData?.getData?.address)

  //   return () => {

  //   }
  // }, [userInfoCacheData?.getData?.address])

  const [hasPluginExtension, setHasPluginExtension] = useState<boolean>();

  useEffect(() => {
    if (hasPlugin) {
      setHasPluginExtension(true);
    } else {
      setHasPluginExtension(false);
    }
    console.log("hasPlugin***", hasPlugin);
    return () => {};
  }, [window.effectiai]);

  // async function cheackAirdrop() {
  //   const response:AirdropInfoResponseData = await getAirdropInfoWithAddress()
  //   return false;
  // }

  // 限制用户访问指定的网页
  useEffect(() => {
    (async () => {
      console.log(
        "router**authInfoCacheData.getData.canAuth**",
        authInfoCacheData.getData.canAuth,
        userInfoCacheData.getData.address,
        location.pathname
      );
      if (
        authInfoCacheData.getData.canAuth === true &&
        userInfoCacheData.getData.address !== undefined
      ) {
        console.log(
          "router**innnnh**",
          !userInfoCacheData.getData.address,
          location.pathname !== routerConstant.TASK
        );
        //没有钱包，必定跳转到任务
        //没有插件，必定跳转到任务
        if (
          (!userInfoCacheData.getData.address ||
            hasPluginExtension === false) &&
          location.pathname !== routerConstant.TASK
        ) {
          navigate(routerConstant.TASK, { replace: true });
          return;
        }
        //是否跳转到空投页
        // const needNext = cheackAirdrop();
        // if(!needNext) return;

        //登陆后不能访问的页面
        const completeMatchList = [
          routerConstant.SIGNIN,
          routerConstant.SIGNUP,
          routerConstant.WELCOME,
        ];

        let index = completeMatchList.findIndex((item) => {
          let isOK = item === location.pathname;
          return isOK;
        });
        // console.log("router**location**", location)
        console.log("router**already login**location**", location);
        console.log("router**already login**allow visit**", index < 0);
        console.log("router**uid**", userInfoCacheData.getData.uid);
        //登录后仍访问某些页面会自动返回首页
        if (index > -1 && location.pathname !== routerConstant.AIAPPS) {
          navigate(routerConstant.AIAPPS, { replace: true });
        }
      } else if (
        authInfoCacheData.getData.canAuth === false ||
        authInfoCacheData.getData.canAuth === undefined
      ) {
        //未登录允许访问的页面列表
        const completeMatchList = [
          routerConstant.HOMEPAGE,
          routerConstant.WELCOME,
          routerConstant.SIGNIN,
          routerConstant.SIGNUP,
          routerConstant.DASHBOARD,
          routerConstant.MAINPAGE,
          routerConstant.AIAPPS,
          routerConstant.PASSWORD_FORGET,
          routerConstant.PASSWORD_RESET,
        ];

        let index = completeMatchList.findIndex((item) => {
          let isOK = location.pathname === item;

          return isOK;
        });

        if (index < 0) {
          console.log(
            "router**location.pathname**inini",
            location.pathname,
            location.pathname.startsWith(routerConstant.INVITE_PREFIX)
          );
          //路径部分匹配也允许访问
          index = location.pathname.startsWith(routerConstant.INVITE_PREFIX)
            ? 1
            : -1;
        }

        console.log("router**not login**location**", location);
        console.log("router**not login**allow visit***", index > -1);
        console.log("router**uid**", userInfoCacheData.getData.uid);
        //未登录自动访问某些页面自动返回首页
        if (index < 0) {
          // console.log('router**unlogin**')
          //test
          navigate(routerConstant.DASHBOARD, { replace: true });
        }
      }
    })();

    return () => {};
  }, [
    authInfoCacheData.getData?.canAuth,
    userInfoCacheData.getData?.address,
    hasPluginExtension,
  ]);

  //没绑定送去绑定，绑定了页面不变
  // useEffect(() => {
  //   // if(location.pathname !== routerConstant.DASHBOARD || laterClicked) return;
  //   if(authInfoCacheData?.getData?.accessToken && userInfoCacheData?.getData?.uid){
  //     if(!!userInfoCacheData?.getData?.address){
  //       // navigate(routerConstant.DASHBOARD,{replace:true})
  //     }
  //     else{
  //       navigate(routerConstant.TASK,{replace:true})
  //     }
  //   }

  //   return () => {

  //   }
  // }, [userInfoCacheData?.getData?.uid,authInfoCacheData?.getData?.accessToken])

  //刚进来时，已经登陆的auth信息发送给插件
  useEffect(() => {
    if (
      !authInfoCacheData?.getData.accessToken ||
      authInfoCacheData?.getData.sender === "effective-plugin"
    )
      return;
    //发送给插件，同步token
    const msg: AuthMessageData = {
      ...authInfoCacheData?.getData,
      sender: "effective-web",
      key: process.env.REACT_APP_DOMAIN_NAME || "",
      action: ActionType.SyncData,
    };
    console.error("发送消息给plugin****msg****", msg);
    window.postMessage(JSON.stringify(msg), document.URL);

    return () => {};
  }, []);

  return {};
};

export default useUserInfo;
function async(arg0: () => void) {
  throw new Error("Function not implemented.");
}
