import React from 'react'
import { useLocation } from 'react-router-dom'

import IntroCard from '../components/IntroCard.react'
import Input from '../components/Input.react'
import Button from '../components/Button.react'
import Error from '../components/Error.react'
import useResetPassword from './useResetPassword'

function ResetPassword({ open, address }) {

  const { btnAvailable, formData, errorMsg, setPassword, setConfirmpassword, onSubmitClick } = useResetPassword()

  return <div className="relative flex flex-1 w-full h-full bg-black ">
    <div className='relative flex flex-1 flex-row'>
      <IntroCard />
      <div className='relative flex flex-1 justify-center'>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            e.stopPropagation()
            return false
          }}
          className='flex flex-col justify-center space-y-4 w-[310px]'>
          <div className='font-sans text-5xl uppercase font-medium'>Reset Password</div>
          <Input
            type='password'
            title={'New password'}
            value={formData.password.value}
            error={(formData.password.type === 'empty' || formData.password.type === 'tooshort') ?'Must contains 10 characters':
            ((formData.password.type === 'toolong') ?'Must less than 20 characters':((formData.password.type === 'illegal') ?'Illegal characters':''))}
            onChange={e => { setPassword(e.target.value) }}
          />
          <Input
            type='password'
            title={'Confirm your new password'}
            value={formData.confirmpassword.value}
            onChange={e => { setConfirmpassword(e.target.value) }}
          />
          <Button
            disabled={!btnAvailable}
            gradient
            onClick={() => { onSubmitClick() }}
          >Confirm</Button>
        </form>
        {errorMsg && <Error>{errorMsg}</Error>}
      </div>
    </div>
  </div>
}

export default ResetPassword