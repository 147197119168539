import React from 'react'
import { useMatch, useResolvedPath, NavLink } from 'react-router-dom'

import IconDashboard from '../images/icon/dashboard.png'
import IconDashboardHover from '../images/icon/dashboard_hover.png'
import IconPortal from '../images/icon/portal.png'
import IconPortalHover from '../images/icon/portal_hover.png'
import IconScore from '../images/icon/score.png'
import IconScoreHover from '../images/icon/score_hover.png'
import IconUser from '../images/icon/user.png'
import IconUserHover from '../images/icon/user_hover.png'

import { DASHBOARD, AIAPPS, MYPROFILE, REPUTATION_SCORE, WELCOME, PERSONA_DASHBOARD } from '../router/constants'
import useUser from './user-hook'
import { X as IconX, Tg as IconTG, Doc as IconDoc } from '../svg/components'

function NavItem(props) {

  const { to, icon, iconHover, children, disabled } = props

  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });

  return <>
    <NavLink className={'flex flex-col'} to={to}>
      <button
        disabled={disabled}
        className={match ? 'nav-item flex flex-row items-center h-14 px-4 rounded-full bg-bg-gradient'
          : 'flex flex-row items-center h-14 px-4 rounded-full bg-transparent hover:bg-black disabled:opacity-30 disabled:cursor-not-allowed'}>
        {
          match ? (
            <>
              <img className='w-6 mr-4 object-contain z-10' src={iconHover} />
              <span className='font-sans text-base font-medium text-transparent bg-yellow-gradient bg-clip-text z-10'>{children}</span>
            </>
          ) : (
            <>
              <img className='w-6 mr-4 object-contain z-10' src={icon} />
              <span className='font-sans text-base font-medium text-white z-10'>{children}</span>
            </>
          )
        }
      </button>
    </NavLink>
  </>
}

function LeftNav() {

  const { isLogined } = useUser()
  return <div className='flex flex-col justify-between'>
    <div className='left-nav-gradient relative flex flex-col w-[352px] min-w-[352px] p-6 space-y-4'>
      <NavItem
        to={AIAPPS}
        icon={IconPortal}
        iconHover={IconPortalHover}
      >Top AIs</NavItem>
      <NavItem
        to={PERSONA_DASHBOARD}
        icon={IconDashboard}
        iconHover={IconDashboardHover}
      >Dashboard</NavItem>
      <NavItem
        to={isLogined ? REPUTATION_SCORE : WELCOME}
        // disabled
        icon={IconScore}
        iconHover={IconScoreHover}
      >Reputation Score</NavItem>
      <NavItem
        to={isLogined ? MYPROFILE : WELCOME}
        icon={IconUser}
        iconHover={IconUserHover}
      >My Profile</NavItem>
    </div>
    <div className='flex flex-col font-sans px-6 pb-8'>
      <div className='flex flex-col text-base text-left leading-[30px] tracking-[-0.2px]'>
        <span className='font-medium'>The Open Infra for AI Acceleration</span>
        <span className='font-light italic'>E/ACC! NOW! TO AGI!</span>
      </div>
      <div className='flex flex-row space-x-5 mt-4'>
        <a
          className='text-2xl cursor-pointer'
          href='https://x.com/ai_eacc'
          target='_blank'
        >
          <IconX />
        </a>
        <a
          className='text-2xl cursor-pointer'
          href='https://t.me/effectiveaichat'
          target='_blank'
        >
          <IconTG />
        </a>
        <a
          className='text-2xl cursor-pointer'
          href='https://docs.effectiveai.xyz'
          target='_blank'
        >
          <IconDoc />
        </a>
      </div>
    </div>
  </div>
}

export default LeftNav