//  手机号
const isPhone = /^1[3-9]\d{9}$/;
// const isPhone = /(\d{3}-|\d{4}-)?(\d{8}|\d{7})?/;

// 邮箱
const isEmail = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
// 密码格式
const isPassword = /^[\w~`!@#$%^&*()-+=|\\<>,.?/\[\]{}:;'"]{10,20}$/;
// 身份证
const isIdentity = /^(([1][1-5])|([2][1-3])|([3][1-7])|([4][1-6])|([5][0-4])|([6][1-5])|([7][1])|([8][1-2]))\d{4}(([1][9]\d{2})|([2]\d{3}))(([0][1-9])|([1][0-2]))(([0][1-9])|([1-2][0-9])|([3][0-1]))\d{3}[0-9xX]$/;
//港澳
const isHKCard = /^([A-Z]\d{6,10}(\(\w{1}\))?)$/;
//台
const isTWCard = /^\d{8}|^[a-zA-Z0-9]{10}|^\d{18}$/;
// 护照
const isPassPortCard = /^([a-zA-z]|[0-9]){5,17}$/;

// 特殊字符
const specialcharactersReg = /^(?!_)(?!.*?_$)[a-zA-Z0-9_\u4e00-\u9fa5]+$/;
const specialcharactersReg2 =/^[-.@_a-zA-Z0-9]+$/;


const checkIsEmail = (val: string) => {
    if(!val) return false;
    var r = isEmail
    const flag = r.test(val);
    return flag;
};

const checkContainSpeicalChars = (val: string) => {
    if(!val) return false;
    var r = specialcharactersReg2
    const flag = r.test(val);
    return !flag;
};

const checkIsPassword = (val: string) => {
    if(!val) return false;
    var r = isPassword;
    const flag = r.test(val);
    return flag;
};

const checkIsComplexPassword = (str:string)=>{
    // 判断字符串是否满足条件
    // const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-=|]).{8,20}$/;
    // 检查字符串是否包含大小写字母和特殊字符
    // const isValidString = regex.test(str);
    // return isValidString
  
  
    const regex1 = /[a-z]/;
    const regex2 = /[A-Z]/;
    const regex3 = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/\-=]/;
  
    const a = regex1.test(str);
    const b = regex2.test(str);
    const c = regex3.test(str);
    const d = str.length >7  && str.length < 21

    console.log('checkIsComplexPassword***',a,b,c)
    return a && b && c &&d;
  }

 


export {
    checkIsEmail,
    checkContainSpeicalChars,
    checkIsPassword,
    checkIsComplexPassword
}