import { domain, envType } from "../utils/env";

export const PASSWORD_MIN_LEN = 10;
export const PASSWORD_MAX_LEN = 20;

export const TOKEN_REFRESH_TIME = 10 * 60 * 1000;

declare const window: any;

export const APPNAME = "Effective AI";
export const APPTASKNAME = "Effective.AI";

export const isDev = process.env.REACT_APP_ENV === "dev";

export const isDevPlatform =
  process.env.REACT_APP_DOMAIN_NAME !== "https://app.effectiveai.xyz";
export const TARGET_TIME_ZONE = isDev ? 8 : 0;

export const CAN_USER_INVITE = true; //false;
export const CAN_SCORE_SHOW = true; //false;//true;

export type TwitterAction = "follow" | "share";
export const TwitterActions: TwitterAction = "share";

export const hasPlugin = window.effectiai !== undefined;
export const forceWebDownload = false;

export const pluginDownloadUrl =
  forceWebDownload || isDevPlatform
    ? `${domain}/plugins/ChroniclebyEffectiveAI.zip`
    : "https://chromewebstore.google.com/detail/chronicle-by-effective-ai/bnooefggdiclpcjnfoonnpflfhlnpnpp";

export const getGids = () => {
  const gid =
    envType === "dev"
      ? "G-TZP6DR180N"
      : envType === "prod"
      ? "G-TZP6DR180N"
      : "G-TZP6DR180N";
  const additionGids = envType === "dev" ? undefined : undefined;
  console.log("gids**", gid, additionGids);
  return { gid, additionGids };
};
