import { useEffect, useState, useRef } from "react";
import { CodeMixState, FormUnitType2 } from "../services/formdata";
import { useLocation, useNavigate } from "react-router-dom";
import { decode, encode } from "../utils/saltBase64";
import { MD5 } from "../utils/saltMD5";
import { SIGNIN } from "../router/constants";
import * as stringUtils from "../utils/string";
import {
  checkIsEmail,
  checkIsComplexPassword,
  checkIsPassword,
} from "../utils/regularUtils";
import { PASSWORD_MAX_LEN, PASSWORD_MIN_LEN } from "../services/constants";
import { findMyPwd } from "../api/server/user/app";
import { FindMyCodeResponseData } from "../api/server/user/response";;

export type ResetPasswordData = {
  password: FormUnitType2;
  confirmpassword: FormUnitType2;
};

export const initForgetPasswordData: ResetPasswordData = {
  password: {
    value: "",
    type: "empty",
  },
  confirmpassword: {
    value: "",
    type: "empty",
  },
};


const useResetPassword = () => {

  const navigate = useNavigate();
  const location = useLocation();

  const [formData, setFormData] =
    useState<ResetPasswordData>(initForgetPasswordData);
  //是否能点击confirm按钮
  const [canClickNextBtn, setCanClickNextBtn] = useState<boolean>(false);
  //是否点击了按钮
  const [nextClicked, setNextClicked] = useState(false);

  const [errorMsg, setErrorMsg] = useState('')

  let didCancel = false;

  useEffect(() => {
    const { data } = location.state
    if (data === null || data === undefined) {
      navigate(-1)
    }
    console.log('@@@@@@@@@@2 -- data:', data)
  }, [])

  useEffect(() => {
    setErrorMsg('')
    !nextClicked && checkCanClickNextBtn();
    return () => {
      didCancel = true;
    };
  }, [formData]);

  const checkCanClickNextBtn = () => {
    const val = checkAvailableListForRegister(formData);
    console.log('@@@@@checkCanClickNextBtn@@@@@',val)
    setCanClickNextBtn(val);
  };

  const checkAvailableListForRegister = (list: any): boolean => {
    let available = true;
    // console.log("checkAvailableListForRegister**list**", list);
    for (const item in list) {
      if (typeof list[item] === "object") {
        // console.log("checkAvailableListForRegister**listitem**", typeof (list[item]), item, list[item], item == 'isIllegal')
        available = available && checkAvailableListForRegister(list[item]);
        // console.log("available0**", item, list[item], available)
      } else if (item == "type") {
        available =
          available && (list[item] == "legal" || list[item] == "useful");
        // console.log("checkAvailableListForRegister**listitem**available1**", item, !!!list[item])
        if (!available) return available; //不满足的情况直接可以返回false
      }
    }
    // console.log("checkAvailableListForRegister**listitem**allavailable**",available)
    return available;
  };


  const [requesting, setRequesting] = useState(false)

  const onSubmitClick = async () => {
    if (!btnAvailable) return;
    const pwd1 = formData.password.value
    const pwd2 = formData.confirmpassword.value
    if (pwd1 !== pwd2) {
      setErrorMsg('The passwords entered twice are different.')
      return
    }
    setNextClicked(true);
    setRequesting(true)
    const { data } = location.state
    const { email, code } = data
    const pwd = formData.password.value
    const result = (await findMyPwd(email, pwd, 'uptwd', code))?.data
    const resultData: FindMyCodeResponseData = result;
    setRequesting(false)
    if (resultData.code === 200) {
      //todo reset password
      navigate({ pathname: SIGNIN })
    } else {
      setErrorMsg(resultData.msg)
      setNextClicked(false)
    }
  };

  const btnAvailable = canClickNextBtn && !requesting;

  const setPassword = (value: string) => {
    setErrorMsg('')
    const passwordType = !value
      ? "empty"
      : value.length < PASSWORD_MIN_LEN
        ? "tooshort"
        // : value.length > PASSWORD_MAX_LEN
        //   ? "toolong"
        //   : !checkIsPassword(value)
        //     ? "illegal"
            : "legal";
    console.log("passwordType**", passwordType);
    setFormData({
      ...formData,
      password: {
        value,
        type: passwordType,
      },
      confirmpassword: {
        value: formData.confirmpassword.value,
        type: !formData.confirmpassword.value
          ? "empty"
          : value !== formData.confirmpassword.value
            ? "illegal"
            : "legal",
      },
    });
  };


  const setConfirmpassword = (value: string) => {
    setErrorMsg('')
    setFormData({
      ...formData,
      confirmpassword: {
        value: value || "",
        type: !value
          ? "empty"
          : value !== formData.password.value
            ? "illegal"
            : "legal",
      },
    });
  };

  return {
    btnAvailable, formData, errorMsg,
    onSubmitClick, setPassword, setConfirmpassword,
  };
};

export default useResetPassword;
