import React, { useEffect } from 'react'

import IntroCard from '../components/IntroCard.react'
import Button from '../components/Button.react'
import { useNavigate } from 'react-router-dom'
import { useWeb3ModalAccount } from '@web3modal/ethers5/react';

function Welcome({open, address}) {
  const navigate = useNavigate();
  // const { address:walletAddress } = useWeb3ModalAccount()
  // useEffect(() => {
  //   walletAddress && navigate('/')
  
  //   return () => {
      
  //   }
  // }, [walletAddress])
  

  return <div className="relative flex flex-1 w-full h-full bg-black ">
    <div className='relative flex flex-1 flex-row'>
      <IntroCard />
      <div className='relative flex flex-1 justify-center'>
        <div className='flex flex-col justify-center w-[310px] space-y-4'>
          <div className='font-sans text-5xl uppercase font-medium'>Welcome</div>
          <div className='flex flex-col space-y-3'>
            <Button onClick={()=>{navigate('/signin')}} gradient >Log in</Button>
            <Button onClick={()=>{navigate('/signup')}}>Sign up</Button>
          </div>
          {/* <span className='font-sans text-lg text-white font-medium'>or</span>
          <Button onClick={open}>Connect Wallet</Button> */}
        </div>
      </div>
    </div>
  </div>
}

export default Welcome