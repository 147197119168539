import { get, post, put, upload } from "../request";
import * as api from "./index";

export const getUrl = (url: string) => {
  return get(url);
};

export const signUp = (
  userName: string,
  email: string,
  passWord: string,
  inviteCode: string,
  walletAddress: string
) => {
  const raw = {
    username: userName,
    email,
    password: passWord,
    inviteCode: inviteCode,
    address: walletAddress,
  };

  return post(api.signUp, raw);
};

export const logIn = (userName: string, passWord: string) => {
  const raw = {
    username: userName,
    password: passWord,
  };

  return post(api.logIn, raw);
};

export const changePassword = (oldPassword: string, newPassword: string) => {
  const queryParam = {
    oldPassword,
    newPassword,
  };
  const query = new URLSearchParams(queryParam);

  return put(`${api.changePassword}?${query}`);
};

export const createInviteCode = () => {
  const raw = {};
  return post(api.createInviteCode, raw);
};

export const changeWalletAddress = (uid: string, address: string,signMessage:string,signHash:string) => {
  const raw = {
    userId: Number(uid),
    address,
    signmessage: signMessage,
    signhash: signHash,
  };
  return post(api.changeWalletAddress, raw);
};

// export const getEmailVerifyCodeForRegister = (email: string) => {
//   // 查询参数
//   const queryParam = {
//     email,
//   };
//   const query = new URLSearchParams(queryParam);
//   console.log("getEmailVerifyCodeForRegister***", query);
//   return get(`${api.getEmailVerifyCode}?${query}`);
// };

export enum EmailVerifyCodeType {
  Register = "REGISTER",
  ForgetPassword = "CHANGE_PASSWORD",
  ChangePassword = "CHANGE_PASSWORD",
  ETHWithdraw = "ETH_WITHDRAW",
  EutWithdraw = "EUT_WITHDRAW",
  NFTWithdraw = "NFT_WITHDRAW",
  HotWithdraw = "HOT_WITHDRAW",
  PotWithdraw = "POT_WITHDRAW",
  USDTWithdraw = "USDT_WITHDRAW",

  //ETH_WITHDRAW-ETH提现 NFT_WITHDRAW-NFT提现
}

//new
export const isUsernameRegistered = (id: string) => {
  const form = new FormData();
  form.append("username", id);
  return post(api.isUsernameRegister, form);
};

export const isEmailRegistered = (id: string) => {
  const form = new FormData();
  form.append("email", id);
  return post(api.isEmailRegister, form);
};

export const getUserInfo = () => {
  const raw = {};
  return get(api.userInfo);
};

export const getEmailVerifyCodeForRestPwd = (email: string) => {
  // 查询参数
  const queryParam = {
    email,
  };
  const query = new URLSearchParams(queryParam);
  console.log("getEmailVerifyCodeForRestPwd***", query);
  return get(`${api.getEmailVerifyCode}?${query}`);
};

export const verifyEmailCode = (
  email: string,
  verifyCode: string
  // type: EmailVerifyCodeType
) => {
  const raw = {
    // type,
    email,
    verifyCode,
  };

  return post(api.verifyEmailCode, raw);
};

export const resetPassword = (
  oldPassword: string,
  newPassword: string,
  email: string
) => {
  // 查询参数
  const queryParam = {
    oldPassword,
    newPassword,
    email,
  };
  const query = new URLSearchParams(queryParam);

  return put(`${api.verifyEmailCode}?${query}`);
};

export const getEmailCode = (email: string, type: "uptwd" | string) => {
  const url = `${api.getEmailCode}?email=${email}&type=${type}`;
  return get(url);
};

export const checkEmailCode = (
  email: string,
  code: string,
  type: "uptwd" | string
) => {
  const raw = {
    email: email,
    code: code,
    type: type,
  };
  return post(api.checkEmailCode, raw);
};

export const findMyPwd = (
  email: string,
  newPwd: string,
  type: "uptwd" | string,
  code: string
) => {
  const url = `${api.findMyPwd}?newPassword=${newPwd}&email=${email}&type=${type}&code=${code}`;
  return put(url);
};

export const getWebsite = () => {
  return get(api.getWebsite);
};

export enum WebsiteCategory{
  All=0,
  Top100,
  Top50Growth,
  Top20AixWeb3,
  Top50Aiwebproducts,
  Top50Aiapps
}

export const getWebsiteWithCatergory = (cat:WebsiteCategory) => {
  const formData = new FormData();
  formData.append('category',cat.toString())
  return post(api.getWebsiteWithCatergory,formData);
};