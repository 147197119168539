import { get, post, put, upload } from "../request";
import * as api from "./index";

export type AirdropType ="effectiveai" | 'solana'
export const getAirdropInfoWithAddress = (handle:string,type:AirdropType) => {
  const raw = {
    handle,type
  }
  const url = `${api.getAirdropInfoWithAddress}`;
  return post(url,raw);
};

export type  AirdropInfoRequestData= {
  handle: string,
  amount: number,
  isUsed: boolean,
  type: AirdropType
};

export const clearAirdropAmountWithAddress = (raw:AirdropInfoRequestData) => {
  const url = `${api.clearAirdropAmountWithAddress}`;
  return post(url,raw);
};
