import React from 'react'

import useDashboard from './useDashboard'
import { Info as IconInfo, Arrow as IconArrow } from '../svg/components'
import { parseLevel } from '../utils/string'
import { parseValueInt } from '../utils/CoinUtils'
import { CAN_SCORE_SHOW } from '../services/constants'
import { maxLevel, LevelData, calculateNextInvite } from '../utils/LevelUtils'
import { docsUrl } from '../utils/ShareUtils'

function ReputationScore() {

  const dashboard = useDashboard()
  const { data, timeValue, periodData, totalData } = dashboard

  const renderNextInvite = () => {
    if (data === null || data === undefined) {
      return '--'
    }
    const level = data.level
    const inviteCount = data.inviteCount
    return calculateNextInvite(level, inviteCount)
  }

  const renderLevel = () => {
    if (data === null || data === undefined) {
      return (
        <div className='flex flex-row'>
          {LevelData.map((item) => renderLevelItem(item.level, 0))}
        </div>
      )
    }
    return (
      <div className='flex flex-row'>
        {LevelData.map((item) => renderLevelItem(item.level, data.level))}
      </div>
    )
  }

  const renderLevelItem = (level, currentLevel) => {
    console.log('@@@@@@@@@@ renderLevelItem ---- level:', level)
    console.log('@@@@@@@@@@ renderLevelItem ---- currentLevel:', currentLevel)
    const levelName = parseLevel(level)
    const levelData = LevelData.find(item => item.level === level)
    const levelContent = `${levelData.levelCount} referrals needed`
    console.log('@@@@@@@@@@ renderLevelItem ---- levelData:', levelData)
    if (currentLevel === 0) {
      return (
        <div key={level} className='flex flex-col flex-1 py-2 bg-[#FFFFFF29] rounded-2xl'>
          <div className='text-2xl leading-6 font-bold uppercase font-score text-[#6B6F80]'>{levelName}</div>
          <div className='mt-2.5 text-[13px] leading-[13px] font-medium uppercase font-sans text-[#6B6F80]'>{levelContent}</div>
        </div>
      )
    }

    if (currentLevel === level) {
      return (
        <>
          <div key={level} className='flex flex-col flex-1 py-2 bg-yellow-gradient rounded-full'>
            <div className='text-2xl leading-6 font-bold text-white uppercase font-score'>{levelName}</div>
            <div className='mt-2.5 text-[13px] leading-[13px] font-medium text-white uppercase font-sans'>{levelContent}</div>
          </div>
          {
            level < maxLevel && (
              <div key={level + 'next'} className='flex items-center justify-center w-[27px] text-2xl bg-yellow-gradient rounded-full'>
                <IconArrow />
              </div>
            )
          }
        </>
      )
    }

    if (level - currentLevel === 1) {
      return (
        <div key={level} className='flex flex-col flex-1 py-2 bg-yellow-gradient-2 rounded-2xl'>
          <div className='text-2xl leading-6 font-bold text-transparent uppercase font-score bg-gradient-to-r from-[#FFA957] via-[#D0C1F1] to-[#B795FF] bg-clip-text'>{levelName}</div>
          <div className='mt-2.5 text-[13px] leading-[13px] font-medium text-transparent uppercase font-sans bg-gradient-to-r from-[#FFA957] via-[#D0C1F1] to-[#B795FF] bg-clip-text'>{levelContent}</div>
        </div>
      )
    }

    if (currentLevel > level) {
      return (
        <div key={level} className='flex flex-col flex-1 py-2 bg-[#FFFFFF29] rounded-full'>
          <div className='text-2xl leading-6 font-bold text-transparent uppercase font-score bg-gradient-to-r from-[#FFA957] via-[#D0C1F1] to-[#B795FF] bg-clip-text'>{levelName}</div>
          <div className='mt-2.5 text-[13px] leading-[13px] font-medium text-transparent uppercase font-sans bg-gradient-to-r from-[#FFA957] via-[#D0C1F1] to-[#B795FF] bg-clip-text'>{levelContent}</div>
        </div>
      )
    }

    if (currentLevel < level) {
      return (
        <div key={level} className='flex flex-col flex-1 py-2 bg-[#FFFFFF29] rounded-2xl'>
          <div className='text-2xl leading-6 font-bold uppercase font-score text-[#6B6F80]'>{levelName}</div>
          <div className='mt-2.5 text-[13px] leading-[13px] font-medium uppercase font-sans text-[#6B6F80]'>{levelContent}</div>
        </div>
      )
    }
  }

  // const renderDefaultLevel = () => {
  //   return <div className='flex flex-row'>
  //     <div className='flex flex-col flex-1 py-2 bg-[#FFFFFF29] rounded-full'>
  //       <div className='text-2xl leading-6 font-bold text-transparent uppercase font-score bg-gradient-to-r from-[#FFA957] via-[#D0C1F1] to-[#B795FF] bg-clip-text'>Tier i</div>
  //       <div className='mt-2.5 text-[13px] leading-[13px] font-medium text-transparent uppercase font-sans bg-gradient-to-r from-[#FFA957] via-[#D0C1F1] to-[#B795FF] bg-clip-text'>1 referrals needed</div>
  //     </div>
  //     <div className='flex flex-col flex-1 py-2 bg-yellow-gradient rounded-full'>
  //       <div className='text-2xl leading-6 font-bold text-white uppercase font-score'>Tier Ii</div>
  //       <div className='mt-2.5 text-[13px] leading-[13px] font-medium text-white uppercase font-sans'>3 referrals needed</div>
  //     </div>
  //     <div className='flex items-center justify-center w-[27px] bg-yellow-gradient rounded-full'>
  //       <img src={IconArrow} />
  //     </div>
  //     <div className='flex flex-col flex-1 py-2 bg-yellow-gradient-2 rounded-2xl'>
  //       <div className='text-2xl leading-6 font-bold text-transparent uppercase font-score bg-gradient-to-r from-[#FFA957] via-[#D0C1F1] to-[#B795FF] bg-clip-text'>Tier iII</div>
  //       <div className='mt-2.5 text-[13px] leading-[13px] font-medium text-transparent uppercase font-sans bg-gradient-to-r from-[#FFA957] via-[#D0C1F1] to-[#B795FF] bg-clip-text'>10 referrals needed</div>
  //     </div>
  //     <div className='flex flex-col flex-1 py-2 bg-[#FFFFFF29] rounded-2xl'>
  //       <div className='text-2xl leading-6 font-bold uppercase font-score text-[#6B6F80]'>Tier iV</div>
  //       <div className='mt-2.5 text-[13px] leading-[13px] font-medium uppercase font-sans text-[#6B6F80]'>20 referrals needed</div>
  //     </div>
  //     <div className='flex flex-col flex-1 py-2 bg-[#FFFFFF29] rounded-2xl'>
  //       <div className='text-2xl leading-6 font-bold uppercase font-score text-[#6B6F80]'>Tier V</div>
  //       <div className='mt-2.5 text-[13px] leading-[13px] font-medium uppercase font-sans text-[#6B6F80]'>100 referrals needed</div>
  //     </div>
  //   </div>
  // }


  return <div className='flex flex-col flex-1 p-6 space-y-6'>
    <div className='flex flex-row p-6 space-x-6 bg-[#1E1E1E] rounded-3xl'>
      <span className='font-sans text-lg font-medium leading-5 text-white'>Epoch #{periodData?.roundNumber !== undefined ? periodData?.roundNumber : '--'}</span>
      <span className='font-sans text-lg font-medium leading-5 text-transparent bg-yellow-gradient bg-clip-text'>Ending in {
        timeValue.map((item, index) => {
          if (index === 0)
            return <span key={index}>{item}</span>
          return <span key={index}>:{item}</span>
        })
      }</span>
      <div className='flex flex-row flex-wrap text-left'>
        <span className='font-sans text-lg leading-5 text-white'>Total E/ACC Points Pool in EPOCH #{periodData?.roundNumber !== undefined ? periodData?.roundNumber : '--'}:&nbsp;</span>
        <span className='font-sans text-lg font-bold leading-5 text-white'>{totalData?.totalWeightedTime ? parseValueInt(totalData?.totalWeightedTime.toString()) : '--'}</span>
      </div>
      <div className='flex flex-row flex-wrap text-left'>
        <span className='font-sans text-lg leading-5 text-white'>Total Participates(Beta invitation only):&nbsp;</span>
        <span className='font-sans text-lg font-bold leading-5 text-white'>{totalData?.totalParticipants ? parseValueInt(totalData?.totalParticipants.toString()) : '--'}</span>
      </div>
    </div>
    <div className='flex flex-col p-6 bg-[#1E1E1E] rounded-3xl'>
      <div className='flex flex-row justify-between font-sans'>
        <h6 className='text-lg leading-[21px] font-bold text-white uppercase'>Reputation score</h6>
        <div className='flex flex-row items-center'>
          <span className='mr-2 text-xs leading-3 font-medium text-white uppercase'>Rules</span>
          <span
            className='inline-flex text-sm cursor-pointer'
            onClick={() => { window.open(docsUrl) }}
          >
            <IconInfo />
          </span>
        </div>
      </div>
      <div className='flex mt-[18px] font-score'>
        <span className='text-6xl leading-[60px] font-bold text-transparent bg-gradient-to-r from-[#FFA957] via-[#D0C1F1] to-[#B795FF] bg-clip-text'>{!CAN_SCORE_SHOW ? 'N/A' : (data?.score ?? '--')}</span>
      </div>
    </div>
    <div className='flex flex-col p-6 bg-[#1E1E1E] rounded-3xl'>
      <div className='flex flex-row mb-6 space-x-12'>
        <div className='flex flex-col'>
          <div className='text-xs leading-3 font-medium text-white text-left uppercase font-sans'>Referrals</div>
          <div className='flex mt-2 font-score'>
            <span className='text-6xl leading-[60px] font-bold text-transparent uppercase font-score bg-gradient-to-r from-[#FFA957] via-[#D0C1F1] to-[#B795FF] bg-clip-text'>{data?.inviteCount ?? '--'}</span>
          </div>
        </div>
        <div className='flex flex-col'>
          <div className='text-xs leading-3 font-medium text-white text-left uppercase font-sans'>Current Tier</div>
          <div className='flex mt-2 font-score'>
            <span className='text-6xl leading-[60px] font-bold text-transparent uppercase font-score bg-gradient-to-r from-[#FFA957] via-[#D0C1F1] to-[#B795FF] bg-clip-text'>{parseLevel(data?.level)}</span>
          </div>
        </div>
        <div className='flex flex-col'>
          <div className='text-xs leading-3 font-medium text-white text-left uppercase font-sans'>Referrals needed for next TiER</div>
          <div className='flex mt-2 font-score'>
            <span className='text-6xl leading-[60px] font-bold text-transparent uppercase font-score bg-gradient-to-r from-[#FFA957] via-[#D0C1F1] to-[#B795FF] bg-clip-text'>{renderNextInvite()}</span>
          </div>
        </div>
      </div>
      {renderLevel()}
    </div>
  </div>
}

export default ReputationScore