import * as React from "react";
import type { SVGProps } from "react";
const SvgUnlink = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 19 20"
    {...props}
  >
    <path
      stroke="#3CEC82"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.561}
      d="M12.757 16.243v-1.56m1.561-1.562h1.56M5.775 9.449 4.67 10.552a3.121 3.121 0 1 0 4.415 4.414l1.103-1.103M4.954 6.879h-1.56m3.12-3.122v1.561m2.57.82 1.104-1.104a3.121 3.121 0 0 1 4.414 4.414L13.5 10.552"
    />
  </svg>
);
export default SvgUnlink;
