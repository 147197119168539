import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import {
  Hot as HotIcon,
  Search as SearchIcon,
  Close as CloseIcon,
} from "../svg/components";
import Arrow from "../images/icon/arrow_black.png";
import FinalRoundAI from "../images/FinalRoundAI.png";
import FinalRoundBanner from "../images/banner/final-round.png";

// import ai_product from '../data/ai_product.json'
import useAIPortal from "./useAIPortal";
import Booster from "./Booster.react";
import { WebsiteCategory } from "../api/server/user/app";
import ImgA16z from "../images/a16z.png";

const Categorize = [
  {
    key: "all",
    value: "Featured",
    desc: "Ranked by E/ACC points granted in current epoch, reflecting user active time tracked by Chronicle.",
  },
  {
    key: "top100-trending",
    value: "Top 100 Trending",
    desc: "Top 100 AI products ranked by daily visits, monitored by Effective AI. Updated in August, 2024",
  },
  {
    key: "top50-growth",
    value: "Top 50 Growth",
    desc: "Top 100 AI products ranked by total visits growth last month, monitored by Effective AI. Updated in August, 2024",
  },
  {
    key: "top50-aiwebproducts",
    value: "Top 50 Web",
    desc: (
      <>
        <span>
          Top 50 Gen AI Web Products, ranked by unique monthly visits. Updated
          in August, 2024. Source:
        </span>
        <img className="size-[45px]" src={ImgA16z} />
      </>
    ),
  },
  {
    key: "top50-aiapps",
    value: "Top 50 Mobile",
    desc: (
      <>
        <span>
          Top 50 Gen AI Web Products, ranked by monthly active users. Updated in
          August, 2024. Source:
        </span>
        <img className="size-[45px]" src={ImgA16z} />
      </>
    ),
  },
  {
    key: "top20-aixweb3",
    value: "Top 20 AIxWeb3",
    desc: "Top 20 AIxWeb3 products, monitored by Effective AI. Updated in August, 2024",
  },
];

function AIPortalItem(props) {
  const {
    id,
    epoch,
    name,
    title,
    imageUrl,
    description,
    currentScore,
    currentUserCount,
    currentRank,
    onClick,
    isAll,
    seq,
  } = props;

  return (
    <div className="portal-item relative flex flex-col p-5 w-3/12 min-w-[332px]">
      <a
        href={title}
        target={"_blank"}
        className="flex flex-col"
        onClick={(e) => {
          onClick && onClick();
        }}
      >
        <div className="flex flex-col items-center mx-auto size-[292px] z-10">
          <img
            className="mt-[96px] size-[100px] rounded-2xl"
            src={`/app/${imageUrl}`}
          />
          <div className="mt-3 font-sans text-sm font-medium leading-[14px] text-white">
            {name}
          </div>
        </div>
        <div className="flex flex-col h-[54px] mt-2 space-y-2 z-10 font-sans">
          <div className="line-clamp-2 text-sm leading-[14px] text-white text-left">
            {description}
          </div>
          <div className="flex flex-row items-center space-x-2">
            {isAll && (
              <>
                <div className="inline-flex flex-row text-xs items-center">
                  <span className="font-semibold bg-yellow-gradient bg-clip-text text-transparent">
                    {currentRank}
                  </span>
                  <span className="bg-yellow-gradient bg-clip-text text-transparent">
                    &nbsp;in Epoch #{epoch || "--"}{" "}
                  </span>
                </div>
                <div className="inline-flex flex-row text-xs items-center">
                  <span className="font-semibold">{currentScore}</span>
                  <span>&nbsp;Points</span>
                </div>
                <div className="inline-flex flex-row text-xs items-center">
                  <span className="font-semibold">{currentUserCount}</span>
                  <span>&nbsp;Users</span>
                </div>
              </>
            )}
          </div>
        </div>
        {/* <Booster rank={currentRank} /> */}
        { seq > 0 && (
          <>
            <div className="absolute right-[9px] top-[13px] inline-flex flex-row items-center py-1 px-3 bg-yellow-gradient rounded-full text-white z-50">
              <div className="font-semibold text-[10px] leading-[18px] tracking-[0.25px]">
                #{seq}
              </div>
            </div>
          </>
        )}
      </a>
    </div>
  );
}

function NoResult() {
  return (
    <>
      <div className="flex flex-row py-4 mx-auto font-sans">
        <span className="text-white font-medium text-4xl leading-9">
          No results
        </span>
      </div>
    </>
  );
}

function AIPortal() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { data, periodData, doSearch, filterData } = useAIPortal();
  const [displaySearch, setDisplaySearch] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [currentCategorize, setCurrentCategorize] = useState();

  const [cat, setCat] = useState(WebsiteCategory.All);
  useEffect(() => {
    console.log("cat**", cat);
    doSearch(cat);
  }, [cat]);

  // const [productData, setProductData] = useState()

  // useEffect(() => {
  //   const data = ai_product.data
  //   console.log(data)
  //   setProductData(data)
  // }, [])

  useEffect(() => {
    const categorize = searchParams.get("categorize");
    if (!categorize) {
      setSearchParams({ categorize: "all" });
      setCat(WebsiteCategory.All);
    }
    switch (categorize) {
      case "all":
        setCat(WebsiteCategory.All);
        break;
      case "top100-trending":
        setCat(WebsiteCategory.Top100);
        break;
      case "top50-growth":
        setCat(WebsiteCategory.Top50Growth);
        break;
      case "top20-aixweb3":
        setCat(WebsiteCategory.Top20AixWeb3);
        break;
      case "top50-aiwebproducts":
        setCat(WebsiteCategory.Top50Aiwebproducts);
        break;
      case "top50-aiapps":
        setCat(WebsiteCategory.Top50Aiapps);
        break;
    }
  }, []);

  useEffect(() => {
    const categorize = searchParams.get("categorize");
    if (categorize) {
      const index = Categorize.findIndex((item) => item.key === categorize);
      if (index !== -1) {
        setCurrentCategorize(Categorize[index]);
      }
    }
  }, [searchParams.get("categorize")]);

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      doSearch(cat, searchValue);
    }
  };

  return (
    <div className="flex flex-col flex-1 pr-12 max-h-body overflow-auto">
      {/* <div className='slick-wrapper flex h-[556px] mb-32'>
      <div className='flex z-10 px-[25px] py-[30px] mx-auto'>
        <Carousel
          showStatus={false}
          showThumbs={false}>
          <div className='relative'>
            <img className='max-h-[496px]' src={FinalRoundBanner} />
            <button
              className='absolute left-9 bottom-9 flex flex-row items-center px-6 py-4 border border-black rounded-full z-10'
              onClick={(e) => { window.open('https://www.finalroundai.com/') }}
            >
              <span className='font-sans text-lg text-black font-medium leading-4'>View details</span>
              <div className='flex size-2 ml-2.5'>
                <img className='size-2 object-contain' src={Arrow} />
              </div>
            </button>
          </div>
        </Carousel>
      </div>
    </div> */}
      <div className="flex flex-col">
        <div className="relative flex flex-row mx-auto mt-9 mb-7 p-1 bg-[#232323] rounded-full font-sans">
          <div className="flex flex-row items-center space-x-2">
            {Categorize.map((item, index) => (
              <button
                key={index}
                aria-checked={searchParams.get("categorize") === item.key}
                className="group inline-flex px-6 py-4 bg-transparent rounded-full aria-checked:bg-black"
                onClick={() => {
                  setSearchParams({ categorize: item.key });
                  switch (item.key) {
                    case "all":
                      setCat(WebsiteCategory.All);
                      break;
                    case "top100-trending":
                      setCat(WebsiteCategory.Top100);
                      break;
                    case "top50-growth":
                      setCat(WebsiteCategory.Top50Growth);
                      break;
                    case "top20-aixweb3":
                      setCat(WebsiteCategory.Top20AixWeb3);
                      break;
                    case "top50-aiwebproducts":
                      setCat(WebsiteCategory.Top50Aiwebproducts);
                      break;
                    case "top50-aiapps":
                      setCat(WebsiteCategory.Top50Aiapps);
                      break;
                  }
                }}
              >
                <span className="text-base leading-4 font-medium text-[#E9DEFF] group-aria-checked:text-white">
                  {item.value}
                </span>
              </button>
            ))}
            <button
              className="inline-flex py-3.5 px-4 text-xl outline-none"
              onClick={() => {
                setDisplaySearch(true);
              }}
            >
              <SearchIcon />
            </button>
          </div>
          {displaySearch && (
            <>
              <div className="absolute inset-1 flex flex-row items-center space-x-2">
                <div className="flex flex-row flex-1 items-center px-6 py-4 bg-black rounded-full">
                  <input
                    className="flex-1 text-base leading-4 bg-transparent outline-none"
                    value={searchValue}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                      const v = e.target.value;
                      console.log(v);
                      // doSearch(cat,v);
                      filterData(cat, v);
                    }}
                  />
                  <button
                    className="inline-flex text-xl"
                    onClick={() => {
                      setSearchValue("");
                      setDisplaySearch(false);
                      filterData(cat, "");
                    }}
                  >
                    <CloseIcon />
                  </button>
                </div>
                <button className="inline-flex py-3.5 px-4 text-xl outline-none">
                  <SearchIcon />
                </button>
              </div>
            </>
          )}
        </div>
      </div>
      {!displaySearch && currentCategorize && (
        <div className="portal-item">
          <div className="relative flex flex-row justify-center items-center min-h-[60px] space-x-2 font-bold text-lg leading-5 text-white font-sans z-10">
            {currentCategorize.desc}
          </div>
        </div>
      )}
      <div className="flex flex-row flex-wrap">
        {data &&
          data.length > 0 &&
          data?.map((item, index) => { 
            console.log('aaaa**',cat,item)
            if(cat === WebsiteCategory.Top20AixWeb3){
              return (
                <AIPortalItem
                  key={item.id}
                  epoch={periodData?.roundNumber}
                  {...item}
                  isAll={cat === WebsiteCategory.All}
                />
              )
            }
            else
            return (
            <AIPortalItem
              key={item.id}
              epoch={periodData?.roundNumber}
              {...item}
              seq={index + 1}
              isAll={cat === WebsiteCategory.All}
            />
          )})}
        {/* <AIPortalItem
        epoch={33}
        name={'Effective AI'}
        title={'https://app.effectiveai.xyz'}
        imageUrl={'app.png'}
        description={'Effective AI is an open infrastructure for AI Acceleration.'}
        currentScore={'1'}
        currentUserCount={''}
        currentRank={''}
      /> */}
      </div>
      {data && data.length === 0 && <NoResult />}
    </div>
  );
}

export default AIPortal;
