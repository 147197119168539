import {
  ActionType,
  createReducer,
  createStandardAction
} from 'typesafe-actions';
import { createStructuredSelector } from 'reselect';
import { MessageSender } from '../../services/plugin-message';

export type AuthInfoData = {
  accessToken:string | undefined,
  tokenType?:string | undefined,
  refreshToken?:string | undefined,
  userId?:string | undefined,
  businessId?:string | undefined | null,
  oauthId?:string | undefined,
  avatar?:string | undefined,
  authority?:string | undefined,
  username?:string | undefined,
  nickName?:string | undefined,
  expiresIn?:number | undefined,
  canAuth:boolean | undefined,//用于判断用户是否可以自动登陆的状态，false代表否，true代表可以，undefined代表初始状态,
  sender:MessageSender
}


const initialState :AuthInfoData= {
  accessToken:undefined,
  tokenType:undefined,
  refreshToken:undefined,
  userId:undefined,
  businessId:undefined,
  oauthId:undefined,
  avatar:undefined,
  authority:undefined,
  username:undefined,
  nickName:undefined,
  expiresIn:undefined,
  canAuth:undefined,
  sender:'effective-web'
};

export type Selectors = {
  getData: AuthInfoData;
};

const actions = {
  update: createStandardAction('UPDATE_AUTHINFO_CACHE_DATA')<
  AuthInfoData
  >(),
  reset: createStandardAction('RESET_AUTHINFO_CACHE_DATA')()
};

export type AuthInfoDataActions = ActionType<typeof actions>;

const reducer = () =>
  createReducer<AuthInfoData, AuthInfoDataActions>(
    initialState
  )
    .handleAction(actions.update, (state, { payload }) => ({
      ...state,
      ...payload
    }))
    .handleAction(actions.reset, () => ({...initialState}
      
    ));

const select = createStructuredSelector<AuthInfoData, {}, Selectors>({
  getData: state => state,
});

export { actions, reducer, select };
