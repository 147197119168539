import { AuthInfoData } from "../redux/presist/auth-local-data";

export type MessageSender = "effective-web" | "effective-plugin"
export enum ActionType{
Login,
Logout,
SyncData,
SyncDataReturn
}
// sender: MessageSender;
type WebPluginMessage ={ key: string;action:ActionType }

export type AuthMessageData = AuthInfoData & WebPluginMessage;