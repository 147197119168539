import axiosRetry from "axios-retry";
import axios from "axios";
import { AxiosError } from "axios";

import createAuthRefreshInterceptor from "axios-auth-refresh";

import { baseUrl } from ".";
import {
  IPFS_NODE,
  IPFS_NODES,
  increaseIpfsNodeIndex,
} from "../../utils/ipfsUtils";
import { changeWalletAddress, logIn,signUp } from "./user";
import { clearAirdropAmountWithAddress, getAirdropInfoWithAddress } from "./airdrop";
// import https from 'https'

var JSONBig = require("json-bigint")({ storeAsString: true });

// console.log("baseUrl**",baseUrl)
const client = axios.create({
  baseURL: baseUrl,
  timeout: 20 * 1000,
  // transformResponse 允许自定义原始的响应数据（字符串）
  transformResponse: [
    function (data) {
      try {
        // 如果转换成功则返回转换的数据结果
        return JSONBig.parse(data);
      } catch (err) {
        // 如果转换失败，则包装为统一数据格式并返回
        return data;
      }
    },
  ],
  // 使用Axios忽略证书的方法很简单，只需要在创建Axios实例的时候，通过配置参数来忽略证书
  // httpsAgent: new https.Agent({
  //   rejectUnauthorized: false
  // })
  // withCredentials: true, //表示跨域请求时是否需要凭证
});
// client.defaults.withCredentials = true

// axiosRetry(client, { retries: 3 });

axiosRetry(client, {
  //传入axios实例
  retries: 3, // 设置自动发送请求次数
  retryDelay: (retryCount) => {
    return retryCount * 500; // 重复请求延迟(毫秒)
  },
  shouldResetTimeout: true, //  重置超时时间
  retryCondition: (error) => {
    //true为打开自动发送请求，false为关闭自动发送请求
    console.log("retryCondition =------ ", error);
    // if (error.message.includes('timeout') || error.message.includes("status code")) {
    if (error?.message?.includes("timeout")) {
      return true;
    } else {
      return false;
    }
  },
});

const uploadClient = axios.create({
  baseURL: baseUrl,
  timeout: 240 * 1000,
});

axiosRetry(uploadClient, { retries: 3 });

const get = (url: string) => {
  return client.get(url, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};

// const get2 = (url: string) => {
//   return client.get(url)
// }

const post = (url: string, form?: FormData | any, manualHeaders?: any) => {
  console.log("post**baseUrl**", baseUrl);
  let headers = manualHeaders;
  if (!form) {
    headers = { ...manualHeaders, "Content-Type": "application/json" };
    console.log("postheader**", url, headers);
    return client.post(url, { headers });
  }
  if (form instanceof FormData) {
    headers = {
      ...manualHeaders,
      "Content-Type": "application/x-www-form-urlencoded",
    };
    console.log("postheader**", url, headers);

    return client.post(url, form, { headers });
  } else {
    headers = { ...manualHeaders, "Content-Type": "application/json" };
    console.log("postheader**", url, headers);
    return client.post(url, form, { headers });
  }
};

const put = (url: string, form?: FormData | any, manualHeaders?: any) => {
  let headers = manualHeaders;
  if (!form) {
    headers = { ...manualHeaders, "Content-Type": "application/json" };
    console.log("putheader**", url, headers);
    return client.put(url, { headers });
  }
  if (form instanceof FormData) {
    headers = {
      ...manualHeaders,
      "Content-Type": "application/x-www-form-urlencoded",
    };
    console.log("putheader**", url, headers);

    return client.put(url, form, { headers });
  } else {
    headers = { ...manualHeaders, "Content-Type": "application/json" };
    console.log("putheader**", url, headers);
    return client.put(url, form, { headers });
  }
};

// const upload = (url: string, form: FormData) => {
//   return client.post(url, form, { headers: { 'Content-Type': 'multipart/form-data' } })
// }
const upload = (url: string, form: FormData) => {
  return uploadClient.post(url, form, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const longGet = (url: string) => {
  return uploadClient.get(url, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};
const longPost = (url: string, form?: FormData | any, manualHeaders?: any) => {
  let headers = manualHeaders;
  if (!form) {
    headers = { ...manualHeaders, "Content-Type": "application/json" };
    console.log("longPostheader**", url, headers);
    return uploadClient.post(url, { headers });
  }
  if (form instanceof FormData) {
    headers = {
      ...manualHeaders,
      "Content-Type": "application/x-www-form-urlencoded",
    };
    console.log("longPostheader**", url, headers);

    return uploadClient.post(url, form, { headers });
  } else {
    headers = { ...manualHeaders, "Content-Type": "application/json" };
    console.log("longPostheader**", url, headers);
    return uploadClient.post(url, form, { headers });
  }
};

let authRefreshInterceptorId: undefined | number;
let authHeaderInterceptorId: undefined | number;
const apiInstance = () => {
  if (client) {
    return client;
  }
  throw new Error("No initialised app API");
};

let musicToken: string | undefined = undefined;

// let ipfsRetryTime = 0;
// const IPFS_MAS_RETRY_TIME = 3;

// 添加请求拦截器，在请求头中加token,Cookie
client.interceptors.request.use(
  (config) => {
    // if (!!musicToken && !config.url?.startsWith(IPFS_NODE) && !config.url?.startsWith(SBT_RESPONSEDATA_IPFS_NODE)) {
    //   config.headers.Authorization = musicToken;
    // }
    //存在token且排除ipfs地址
    //refreshtoken也要排除
    if (
      !!musicToken && config.url !== signUp && 
      config.url !== logIn && config.url !== changeWalletAddress && 
      config.url !== getAirdropInfoWithAddress && config.url !== clearAirdropAmountWithAddress &&
      IPFS_NODES.filter((item: any) => config.url?.startsWith(item)).length < 1 
      // &&config.url !== refreshUserToken
    ) {
      config.headers.Authorization = `${musicToken}`;
    }
    console.log("client.interceptors.request**", config);

    // console.log("request.use**", config.url);

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//api axios的响应拦截
client.interceptors.response.use(
  (response: any) => {
    // console.log("response.use**", response);
    // console.log("response**" , response.data.code == 500,response.data.msg == 'user not login')
    //ipfs重试拦截
    const ipfsFilter = IPFS_NODES.filter((item) =>
      response.config.url?.startsWith(item)
    );
    if (ipfsFilter.length > 0) {
      //200正常不处理
      if (response.status == 200) return response;
      //所有节点都尝试过了，都无法正常调用
      if (!increaseIpfsNodeIndex()) {
        return response;
      } else {
        const url = (response.config.url as string).replace(
          ipfsFilter[0],
          IPFS_NODE()
        );
        console.log("response.error**IPFS_NODE**", IPFS_NODE());
        console.log("response.error**url**", url);
        return get(url);
      }
    }
    //尝试在这里拦截token失效的情况
    if (
      response.data?.code === 500 &&
      response.data?.msg === "user not login"
    ) {
      //getAuthToken();
      // location.reload();
      response.status = 401;
      console.log("response.use401**", response);
      // return response;
      const manualErr: AxiosError = {
        config: response.config,
        code: "401",
        request: response.request,
        response: response,
        isAxiosError: true,
        toJSON: function (): object {
          throw new Error("Function not implemented.");
        },
        name: "",
        message: "",
      };
      return Promise.reject(manualErr);
    }
    //接口异常时不处理
    else if (response.status !== 200) {
      return response;
    } else {
      return response;
    }
  },
  //尝试改造，不影响token刷新
  (error) => {
    console.error("serverclient.interceptors.response.use.error", error);
    // console.error("response.error**response**", error?.response);
    // console.error(error?.request);
    // console.error(error?.response)
    if (error?.response?.status === 401) {
      return Promise.reject(error); // 此处要注意error参数，如果写成 return Promise.reject()，则后面的刷新逻辑执行不到
    }

    if (error?.message?.includes("timeout")) {
      const response = error?.response;
      const config = error?.config;
      console.log("response.error**response**", response);
      //ipfs重试拦截
      const ipfsFilter = IPFS_NODES.filter((item) =>
        config.url?.startsWith(item)
      );
      console.log("ipfsFilter**", ipfsFilter);
      if (ipfsFilter.length > 0) {
        if (!increaseIpfsNodeIndex()) {
          //所有节点都尝试过了，都无法正常调用
          return response;
        } else {
          const url = (config.url as string).replace(
            ipfsFilter[0],
            IPFS_NODE()
          );
          console.log("response.error**IPFS_NODE**", IPFS_NODE());
          console.log("response.error**url**", url);
          return get(url);

          config.url = url;
          response.status = 401;
          console.log("response.error**ipfs**401**", response);
          const manualErr: AxiosError = {
            config: config,
            code: "401",
            request: error?.request,
            response: error,
            isAxiosError: true,
            toJSON: function (): object {
              throw new Error("Function not implemented.");
            },
            name: "",
            message: "",
          };
          return Promise.reject(manualErr);
        }
      }
    }
    return Promise.reject();
    // return Promise.resolve(error)
  }
  //这里会影响刷新token的调用
  // error => {
  //   console.log("client.interceptors.response.error --- " , error)
  //   if (error.response) {
  //     switch (error.response.status) {
  //       case 500:
  //         console.log("response.error" , error.response)
  //         break;
  //     }
  //   }
  //   return Promise.reject(error?.response?.data)
  // }
);

uploadClient.interceptors.request.use(
  (config) => {
    if (!!musicToken) {
      config.headers.Authorization = `${musicToken}`;
    }
    console.log("upload request use**", config);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//upload axios的响应拦截
uploadClient.interceptors.response.use(
  (response: any) => {
    console.log("uploadClient**response.use**", response);
    // console.log("response**" , response.data.code == 500,response.data.msg == 'user not login')
    //尝试在这里拦截token失效的情况
    if (response.data?.code == 500 && response.data?.msg == "user not login") {
      //getAuthToken();
      // location.reload();
      response.status = 401;
      console.log("uploadClient**response.use401**", response);
      // return response;
      const manualErr: AxiosError = {
        config: response.config,
        code: "401",
        request: response.request,
        response: response,
        isAxiosError: true,
        toJSON: function (): object {
          throw new Error("Function not implemented.");
        },
        name: "",
        message: "",
      };
      return Promise.reject(manualErr);
    } else {
      return response;
    }
  },
  //尝试改造，不影响token刷新
  (error) => {
    console.error("uploadclient.interceptors.response.use.error", error);
    // console.error("response.error**response**", error?.response);
    // console.error(error?.request);
    // console.error(error?.response)
    if (error?.response?.status === 401) {
      return Promise.reject(error); // 此处要注意error参数，如果写成 return Promise.reject()，则后面的刷新逻辑执行不到
    }

    if (error?.message?.includes("timeout")) {
      const response = error?.response;
      const config = error?.config;
      console.log("response.error**response**", response);
      //ipfs重试拦截
      const ipfsFilter = IPFS_NODES.filter((item) =>
        config.url?.startsWith(item)
      );
      console.log("ipfsFilter**", ipfsFilter);
      if (ipfsFilter.length > 0) {
        if (!increaseIpfsNodeIndex()) {
          //所有节点都尝试过了，都无法正常调用
          return response;
        } else {
          const url = (config.url as string).replace(
            ipfsFilter[0],
            IPFS_NODE()
          );
          console.log("response.error**IPFS_NODE**", IPFS_NODE());
          console.log("response.error**url**", url);
          return get(url);

          config.url = url;
          response.status = 401;
          console.log("response.error**ipfs**401**", response);
          const manualErr: AxiosError = {
            config: config,
            code: "401",
            request: error?.request,
            response: error,
            isAxiosError: true,
            toJSON: function (): object {
              throw new Error("Function not implemented.");
            },
            name: "",
            message: "",
          };
          return Promise.reject(manualErr);
        }
      }
    }
    return Promise.reject();
    // return Promise.resolve(error)
  }
  // error => {
  //   if (error.response) {
  //     switch (error.response.status) {
  //       case 500:
  //         console.log("response.error", error.response)
  //         break;
  //     }
  //   }
  //   return Promise.reject(error.response.data)
  // }
);

const setAuth = (token: string) => {
  musicToken = token;
};

const removeAuth = () => {
  musicToken = undefined;
};

type TokenResponse = {
  accessToken: string | null;
};

// const setRefreshInterceptor = <TokenHandlerResponse extends TokenResponse>(
//   refreshTokenHandler: (failedRequest: any) => Promise<TokenHandlerResponse>
// ) => {
//   const instance = apiInstance();
//   authRefreshInterceptorId = createAuthRefreshInterceptor(
//     instance,
//     refreshTokenHandler, {
//     pauseInstanceWhileRefreshing: true,
//     // statusCodes:[0,200,401,500,504],//好像没有作用，需要把正常 请求转换为错误请求
//     // shouldRefresh:(error)=> error.response?.data.code === '500' || error.response?.data.msg === "user not login"
//   }
//   );
// };

const setRefreshInterceptor2 = (
  refreshTokenHandler: (failedRequest: any) => Promise<any>
) => {
  const instance = apiInstance();
  authRefreshInterceptorId = createAuthRefreshInterceptor(
    instance,
    refreshTokenHandler,
    {
      pauseInstanceWhileRefreshing: true,
      // statusCodes:[401],//好像没有作用，需要把正常 请求转换为错误请求
      // shouldRefresh:(error)=> error.response?.data.code === '500' || error.response?.data.msg === "user not login"
    }
  );
};

// const removeAuth = () => {
//   const instance = apiInstance();
//   // remove interceptors for refresh and auth header
//   instance.axiosInstance.interceptors.request.eject(
//     authRefreshInterceptorId as number
//   );
//   instance.axiosInstance.interceptors.request.eject(
//     authHeaderInterceptorId as number
//   );
// };

export {
  client,
  get,
  post,
  put,
  upload,
  longGet,
  longPost,
  musicToken,
  setAuth,
  removeAuth,
  setRefreshInterceptor2,
};
