import * as React from "react";
import type { SVGProps } from "react";
const SvgSearch = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#E9DEFF"
      d="M8.345 1.627a6.667 6.667 0 0 0 0 13.333c1.53 0 2.981-.526 4.106-1.392l3.628 3.658a.86.86 0 0 0 1.198 0 .86.86 0 0 0 0-1.198l-3.653-3.633a6.74 6.74 0 0 0 1.388-4.101 6.667 6.667 0 0 0-6.667-6.667m0 1.667a5 5 0 1 1 0 10 5 5 0 0 1 0-10"
    />
  </svg>
);
export default SvgSearch;
