import React, { useEffect, useState } from 'react'

import IntroCard from '../components/IntroCard.react'
import Input from '../components/Input.react'
import Button from '../components/Button.react'
import Error from '../components/Error.react'
import { Link, useNavigate } from 'react-router-dom'
import { PASSWORD_RESET } from '../router/constants'
import useForgetPassword from './useForgetPassword'

function ForgetPassword({ open, address }) {

  const { formData, time, btnAvailable, errorMsg, setEmail, setCode, onGetCodeClick, onSubmitClick } = useForgetPassword()

  return <div className="relative flex flex-1 w-full h-full bg-black ">
    <div className='relative flex flex-1 flex-row'>
      <IntroCard />
      <div className='relative flex flex-1 justify-center'>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            e.stopPropagation()
            return false
          }}
          className='flex flex-col justify-center space-y-4 w-[310px]'>
          <div className='font-sans text-5xl uppercase font-medium'>Forget Password</div>
          <Input
            title={'Your Email'}
            value={formData.email.value}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            gradient
            disabled={formData.email.type !== 'legal' || time > 0}
            onClick={() => { onGetCodeClick() }}
          >Send code{time > 0 && ` (Resend in ${time}s)`}</Button>
          <>
            <Input
              title={'Verification code'}
              value={formData.verificationCode.value}
              onChange={e => { setCode(e.target.value) }}
            />
            <Button
              gradient
              disabled={!btnAvailable}
              onClick={() => { onSubmitClick() }}
            >Confirm</Button>
          </>
        </form>
        {
          errorMsg && <Error>{errorMsg}</Error>
        }
      </div>
    </div>
  </div>
}

export default ForgetPassword