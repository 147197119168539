import { useEffect, useState } from "react";
import { CodeMixState, FormUnitType2 } from "../services/formdata";
import { useLocation, useNavigate } from "react-router-dom";
import { useSessionStorage } from "react-storage-complete";
import { domain, projectName } from "../utils/env";
import { decode, encode } from "../utils/saltBase64";
import { MD5 } from "../utils/saltMD5";
import { DASHBOARD, HOMEPAGE } from "../router/constants";
import {
  isEmailRegistered,
  isUsernameRegistered,
  signUp,
} from "../api/server/user/app";
import { DefaultResponseData, ResponseData } from "../api/server/response";
import * as stringUtils from "../utils/string";
import {
  checkContainSpeicalChars,
  checkIsEmail,
  checkIsComplexPassword,
  checkIsPassword,
} from "../utils/regularUtils";
import { PASSWORD_MAX_LEN, PASSWORD_MIN_LEN } from "../services/constants";
import useLogin from "../components/login-hook";

export type SignupFormDataType2 = {
  username: FormUnitType2;
  email: FormUnitType2;
  password: FormUnitType2;
  confirmpassword: FormUnitType2;
  inviteCode: FormUnitType2;
  codeMix: CodeMixState;
  error: string;
};

export const initSignupFormData2: SignupFormDataType2 = {
  username: {
    value: "",
    type: "empty",
  },
  email: {
    value: "",
    type: "empty",
  },
  password: {
    value: "",
    type: "empty",
  },
  inviteCode:  {
    value: "",
    type: "empty",
  },
  confirmpassword: {
    value: "",
    type: "empty",
  },
  codeMix: "init",
  error: "",
};

const useSignup = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // console.log("usesignup1**", domain, projectName);
  const [formData, setFormData] =
    useState<SignupFormDataType2>(initSignupFormData2);
  //是否能发邮件获取验证码
  const [canSendEmailForRegister, setCanSendEmailForRegister] =
    useState<boolean>(false);//
  //是否能点击注册按钮
  const [canClickNextBtn, setCanClickNextBtn] = useState<boolean>(false);
  //是否点击了按钮
  const [nextClicked, setNextClicked] = useState(false);

  const [formLocalData, setFormLocalData, initialized, clear, prefixedKey] =
    useSessionStorage("Sign.Info", "", {
      prefix: domain,
    });

  const [errorMsg, setErrorMsg] = useState('')
  const [cfToken, setCfToken] = useState<string>("123");

  useEffect(() => {
    if (!initialized) return;
    console.log("singup**initialized**", formLocalData, initialized);

    //初始状态从缓存读取数据
    if (!formLocalData) {
      // setFormData(initFormData)
      // console.log("singup**formLocalData**initsate",formLocalData)
    } else {
      const decodeStr = decode(formLocalData);
      // console.log("singup**formLocalData**decodeStr**", decodeStr)
      //没有缓存信息或者缓存信息有误，直接返回
      if (!decodeStr) {
        return;
      }
      const decodeJsonData: SignupFormDataType2 = JSON.parse(decodeStr);
      console.log("singup**initialized**origindata**", decodeJsonData);
      //清空密码
      decodeJsonData.password = initSignupFormData2.password;
      decodeJsonData.confirmpassword = initSignupFormData2.confirmpassword;
      //清空codemix
      decodeJsonData.codeMix = "init";
      //清空 emailtype
     if(decodeJsonData.email.type ='registered') decodeJsonData.email.type = decodeJsonData.email.value? (checkIsEmail(decodeJsonData.email.value)? "legal":"illegal"):'empty';
      console.log("singup**initialized**fixdata**", decodeJsonData);
      setFormData(decodeJsonData);
    }
  }, [initialized]);

  let didCancel = false;
  useEffect(() => {
    if (formData.codeMix == "mixed") return;

    //转换为session存储
    // console.log("singup**formData**", formData,initialized)
    // return;
    const rebuildData: SignupFormDataType2 = JSON.parse(
      JSON.stringify(formData)
    );
    //md5密码
    if (rebuildData.codeMix == "needmix") {
      rebuildData.password.value = MD5(rebuildData.password.value);
      rebuildData.confirmpassword.value = rebuildData.password.value;
    }
    //不记录邮箱和用户名的校验状态
    //不能不记录密码，否则无法传递给后台
    // rebuildData.password = initFormData.password
    // rebuildData.confirmpassword = initFormData.confirmpassword
    if (
      rebuildData.email.type == "useful" ||
      rebuildData.email.type == "registered"
    )
      rebuildData.email.type = "legal";
    if (
      rebuildData.username.type == "useful" ||
      rebuildData.username.type == "registered"
    )
      rebuildData.username.type = "legal";
      rebuildData.error =''
    console.log("singup**formData**", formData);
    const jsonStr = JSON.stringify(rebuildData);
    const encodeStr = encode(jsonStr);
    if (formLocalData != encodeStr && initialized) {
      // console.log("singup**setFormLocalData**encodeStr**", encodeStr,initialized)
      setFormLocalData(encodeStr);
    }

    !nextClicked && checkCanClickNextBtn();
    // checkCanSendEmailForRegister();
    return () => {
      didCancel = true;
    };
  }, [formData]);

  useEffect(() => {
    if (formData.codeMix == "needmix" && nextClicked) {
      setNextClicked(false);
      navigate(HOMEPAGE);
    }
    return () => {};
  }, [formLocalData]);

  const onSendEmail = async () => {
    if (!formData || didCancel || !cfToken) return;
    //注册获取验证码方法变更
    // const getEmailResult = await getEmailVerifyCodeForRegister(
    //   formData.email.value,
    //   cfToken
    // );
    // console.log("signup***getEmailVerifyCode**result**", getEmailResult.data);
    // if (getEmailResult.data.success) {
    //   didCancel = true;
    //   //需要md5数据一下
    //   setFormData({ ...formData, codeMix: "needmix" });
    //   // navigate(REGISTER_VERTIFY_EMAIL)
    // } else {
    //   //重置两个参数为false
    //   setCanSendEmailForRegister(false);
    //   setCanClickNextBtn(false);
    //   //已经被注册
    //   setFormData({
    //     ...formData,
    //     email: {
    //       ...formData.email,
    //       type: "manual-error",
    //     },
    //     error: getEmailResult.data.msg,
    //   });
    // }
  };

  const{onLogin}=useLogin()

  const onRegister = async () => {
    console.log(
      "onRegister**",!formData , didCancel , !cfToken);
    if (!formData || didCancel || !cfToken) return;
    const username = formData.username.value
    const pwd = formData.password.value
    //注册获取验证码方法变更
    const getRegisterResult:ResponseData<any> = (await signUp(
      formData.username.value,
      formData.email.value,
      (formData.password.value),//无法md5
      formData.inviteCode.value,
      ''
    ))?.data;
    console.log("signup*****result**", getRegisterResult);
    if (getRegisterResult.code === 200) {
      didCancel = true;
      //清空数据
      setFormLocalData("");
      //直接登录
      onLogin(username,pwd)
      // navigate(DASHBOARD)
    } else {
      //todo显示错误
      const msg =getRegisterResult.msg
      // setFormData((f)=>{return {...f,error:msg}})
      setErrorMsg(msg)
    }
    setRequesting(false)
    setNextClicked(false)
    setCanSendEmailForRegister(false)
  };

  useEffect(() => {
    // console.log("canSendEmailForRegister**", canSendEmailForRegister)
    // canSendEmailForRegister && onSendEmail();
    //符合条件后进行注册
    canSendEmailForRegister && onRegister();
    return () => {};
  }, [canSendEmailForRegister]);


  const checkCanClickNextBtn = () => {
    const val = checkAvailableListForRegister(formData);
    console.log("checkCanClickNextBtn**", val, formData);
    setCanClickNextBtn(val);
  };

  const checkAvailableListForRegister = (list: any): boolean => {
    let available = true;
    // console.log("checkAvailableListForRegister**list**", list);
    for (const item in list) {
      if (typeof list[item] === "object") {
        // console.log("checkAvailableListForRegister**listitem**", typeof (list[item]), item, list[item], item == 'isIllegal')
        available = available && checkAvailableListForRegister(list[item]);
        // console.log("available0**", item, list[item], available)
      } else if (item == "type") {
        available =
          available && (list[item] == "legal" || list[item] == "useful");
        // console.log("checkAvailableListForRegister**listitem**available1**", item, !!!list[item])
        if (!available) return available; //不满足的情况直接可以返回false
      }
    }
    // console.log("checkAvailableListForRegister**listitem**allavailable**",available)
    return available;
  };

  const checkCanSendEmailForRegister = () => {
    if (!nextClicked) return false;
    //暂时只检查用户名是否注册，邮箱注册的接口暂不可用
    const val =
      formData.username.type == "useful" && formData.email.type == "useful";
    console.log("checkCanSendEmailForRegister**", val, formData);
    setCanSendEmailForRegister(val);
    return val;
  };

  const [requesting, setRequesting] = useState(false);

  // const onSignupClick = async () => {
  //   setNextClicked(true);
  //   console.log(
  //     "onSignupClick**",
  //     canSendEmailForRegister,
  //     canClickNextBtn,
  //     formData,
  //     requesting
  //   );
  //   setRequesting(true);
  //   //每次请求都检查合法性，以防用户名被注册了
  //   if (
  //     formData.username.type == "legal" ||
  //     formData.username.type == "useful"
  //   ) {
  //     //先检查用户名可用性
  //     const result = await isUsernameRegistered(formData.username.value);
  //     const data: DefaultResponseData = result.data;
  //     console.log("isusername**", data);
  //     ////mock未注册
  //     // data.data = false;
  //     if (data?.success) {
  //       //请求成功
  //       if (data?.data) {
  //         setCanSendEmailForRegister(false);
  //         //已经被注册
  //         setFormData({
  //           ...formData,
  //           username: {
  //             ...formData.username,
  //             type: "registered",
  //           },
  //         });
  //       } else {
  //         //检查email
  //         const result = await isEmailRegistered(formData.email.value);
  //         const emailResponseData: DefaultResponseData = result.data;
  //         console.log("isemail**", emailResponseData);
  //         ////mock未注册
  //         // data.data = false;
  //         if (emailResponseData.success) {
  //           //请求成功
  //           if (emailResponseData.data) {
  //             setCanSendEmailForRegister(false);
  //             //已经被注册
  //             setFormData({
  //               ...formData,
  //               email: {
  //                 ...formData.email,
  //                 type: "registered",
  //               },
  //               username: {
  //                 ...formData.username,
  //                 type: "useful",
  //               },
  //             });
  //           } else {
  //             //未被注册
  //             setFormData({
  //               ...formData,
  //               email: {
  //                 ...formData.email,
  //                 type: "useful",
  //               },
  //               username: {
  //                 ...formData.username,
  //                 type: "useful",
  //               },
  //             });
  //           }
  //         }
  //       }
  //     }
  //     setRequesting(false);
  //   }
  // };
  
  const onSignupClick = async () => {
    if(!btnAvailable) return;
    setNextClicked(true);
    console.log(
      "onSignupClick**",
      canSendEmailForRegister,
      canClickNextBtn,
      formData,
      requesting
    );
    setRequesting(true);
    //直接就可以注册了
    setCanSendEmailForRegister(true);
  };
  const btnAvailable = canClickNextBtn && !requesting && !!cfToken;
  
  // console.log("usesignup***btnAvailable**", btnAvailable);
  // console.log("usesignup***canClickNextBtn**", canClickNextBtn);
  // console.log("usesignup***!requesting**", !requesting);
  // console.log("usesignup***!!cfToken**", !!cfToken, cfToken);

  const [inviteCode] = useState<string>(
    stringUtils.decodeQuery("code", location.search) || ""
  );
  // console.log("inviteCode***", inviteCode);
  useEffect(() => {
    // console.log("uf***inviteCode***", inviteCode);
    initialized &&
      inviteCode &&
      setFormData((value) => {
        return { ...value, inviteCode:{
          value: !inviteCode ? "" : inviteCode,
          type:!value ? 'empty' : 'legal',
        } };
      });

    return () => {};
  }, [inviteCode, initialized]);

  const setUsername = (value: string) => {
    setErrorMsg('')
    const hasSpeicalChar = checkContainSpeicalChars(value);
    // console.log("@@@@@@@ --- hasSpeicalChar:", hasSpeicalChar);
    // console.log("@@@@@@@ --- value:", value);
    console.log(hasSpeicalChar ? "illegal" : !value ? "empty" : "legal");
    setFormData({
      ...formData,
      username: {
        value: value || "",
        type: hasSpeicalChar ? "illegal" : !value ? "empty" : "legal",
      },
    });
  };

  const setEmail = (value: string) => {
    setErrorMsg('')
    setFormData({
      ...formData,
      email: {
        value: value || "",
        type: !value ? "empty" : !checkIsEmail(value) ? "illegal" : "legal",
      },
    });
  };

  const setPasssword = (value: string) => {
    setErrorMsg('')
    // const passwordType = !value
    //   ? "empty"
    //   : value.length < PASSWORD_MIN_LEN
    //   ? "tooshort"
    //   : value.length > PASSWORD_MAX_LEN
    //   ? "toolong"
    //   : !checkIsPassword(value)
    //   ? "illegal"
    //   : "legal";
    const passwordType = !value
    ? "empty"
    : value.length < PASSWORD_MIN_LEN
    ? "tooshort"
    // : value.length > PASSWORD_MAX_LEN
    // ? "toolong"
    // : !checkIsPassword(value)
    // ? "illegal"
    : "legal";
    console.log("passwordType**", passwordType);
    setFormData({
      ...formData,
      password: {
        value,
        type: passwordType,
      },
      confirmpassword: {
        value: formData.confirmpassword.value,
        type: !formData.confirmpassword.value
          ? "empty"
          : value !== formData.confirmpassword.value
          ? "illegal"
          : "legal",
      },
    });
  };

  const setConfirmPasssword = (value: string) => {
    setErrorMsg('')
    setFormData({
      ...formData,
      confirmpassword: {
        value: value || "",
        type: !value
          ? "empty"
          : value !== formData.password.value
          ? "illegal"
          : "legal",
      },
    });
  };

  const setInviteCode = (value: string) => {
    setErrorMsg('')
    setFormData({
      ...formData,
      inviteCode: {
        value: value,
        type: !value ? "empty" : "legal",
      },
    });
  };

  return { onSignupClick, btnAvailable, formData,setUsername,setEmail,setPasssword,setConfirmPasssword,setInviteCode,errorMsg };
};

export default useSignup;
