import React, { useEffect, useRef, useCallback, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../redux/app-state";
import { getEventList, getTopevents } from "../api/server/action/app";
import {
  persistActions,
  persistSelectors,
  sharedActions,
  sharedSelectors,
} from "../redux";
import { userInfoLocalData } from "../redux/local";

import { WebsiteCategory, getWebsite } from "../api/server/user/app";
import { WebsiteData } from "../api/server/user/response";
import { PeriodResponseData } from "../api/server/config/response";
import { getPeriod } from "../api/server/config/app";
import { onWebsiteWithCatergory } from "../api/server/user/service";
import useStateRef from "react-usestateref";

const useAIPortal = () => {
  const userInfoCacheData = useSelector<AppState, userInfoLocalData.Selectors>(
    ({ shared }) =>
      sharedSelectors.local.userInfoLocalData(
        shared.local.userInfoLocalData,
        {}
      )
  );
  const [originData, setOriginData] = useState<WebsiteData[]>();
  const [data, setData] = useState<WebsiteData[]>();
  const [periodData, setPeriodData] = useState<PeriodResponseData>();
  const [cat, setCat, catRef] = useStateRef<WebsiteCategory | undefined>(
    WebsiteCategory.All
  );

  useEffect(() => {
    if (userInfoCacheData.getData.uid) {
      // getWebsiteData()
      getPeriodData();
    }
  }, [userInfoCacheData.getData.uid]);

  useEffect(() => {
    if (userInfoCacheData.getData.uid && cat !== undefined) {
      // getWebsiteData()
      getWebsiteDataWithCatergory(cat);
    }
  }, [userInfoCacheData.getData.uid, cat]);

  const getPeriodData = async () => {
    const response = await getPeriod();
    const responseData = response?.data as PeriodResponseData;
    if (responseData) {
      const data = responseData as PeriodResponseData;
      setPeriodData(data);
    }
  };

  const getWebsiteData = async () => {
    const response = await getWebsite();
    if (response.data) {
      const responseData = response.data;
      const data = responseData as WebsiteData[];
      if (data.length > 0) {
        setData(data);
      }
    }
  };

  const getWebsiteDataWithCatergory = async (cat: WebsiteCategory) => {
    const response = await onWebsiteWithCatergory(cat);
    if (response) {
      const responseData = response.data;
      const data = responseData as WebsiteData[];
      if (data) {
        console.log(
          "getWebsiteDataWithCatergory**data",
          data,
          catRef.current,
          cat
        );
        if (catRef.current !== cat) return;
        setData(data);
        setOriginData(data);
      }
    }
    setCat(undefined);
  };

  const doSearch = async (c: WebsiteCategory, searchValue?: string) => {
    // console.log("doSearch**", c, userInfoCacheData.getData.uid);
    setData(undefined)
    if (!userInfoCacheData.getData.uid) {
      setCat(c);
      return;
    }
    if (!searchValue) {
      setCat(c);
    } else {
      // console.log("doSearch**", searchValue);
      console.log(originData);
      const lowSearchValue = searchValue.toLowerCase()
      const d = originData?.filter((item) =>
        item.name.toLowerCase().includes(lowSearchValue)
      );
      console.log(d);
      if (d) setData(d);
      else setData([]);
    }
  };

  const filterData = async (c: WebsiteCategory, searchValue: string) => {
    // console.log("doSearch**", c, userInfoCacheData.getData.uid);
    setData(undefined)
    if (!searchValue) {
      setData(originData)
    } else {
      // console.log("doSearch**", searchValue);
      console.log(originData);
      const lowSearchValue = searchValue.toLowerCase()
      const d = originData?.filter((item) =>
        item.name.toLowerCase().includes(lowSearchValue)
      );
      console.log(d);
      if (d) setData(d);
      else setData([]);
    }
  };

  return { data, periodData, doSearch,filterData };
};

export default useAIPortal;
