import { get, post, put, upload } from "../request";
import * as api from "./index";



export const getPeriod = () => {
  const url = `${api.getPeriod}`
  return get(url);
}

export const getHeaderScore = () => {
  const url = `${api.getHeaderScore}`
  return post(url);
}

