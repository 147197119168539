import React, { useEffect } from 'react'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'

import LeftNav from '../components/LeftNav.react'
import Header from '../components/Header.react'
import Dashboard from '../components/Dashboard.react'
import { DASHBOARD, AIAPPS, MAINPAGE } from '../router/constants'

function Main() {
  const navigate =  useNavigate();
  // const params = useParams();
  const location=useLocation();

  useEffect(() => {
    // console.log('main******',params,location)
    if(location.pathname=== MAINPAGE) navigate(AIAPPS,{replace:true})
    return () => {
      
    }
  }, [location.pathname])
  
  // useEffect(() => {

  //   navigate(DASHBOARD,{replace:true})
  
  //   return () => {
      
  //   }
  // }, [])
  
  return <div className="relative flex flex-col flex-1 w-full h-full bg-black ">
    <Header />
    <div className='flex flex-1'>
      <LeftNav />
      <Outlet />
    </div>
  </div>
}

export default Main