import React from 'react'

function Error(props) {

  const { children } = props

  return <article className='absolute bottom-0 left-0 right-0 flex flex-col py-2.5 bg-[#FFE6E6]'>
    <div className='text-lg font-medium leading-6 text-center text-[#E62E2E] font-sans'>{children}</div>
  </article>
}

export default Error