import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createInviteCode } from "../api/server/user/app";
import { sharedSelectors } from "../redux";
import { AppState } from "../redux/app-state";
import { userInfoLocalData } from "../redux/local";
import { TaskRequestData, updateTask, getTask, sendTask, TaskStatus } from "../api/server/action/app";
import { EventType } from "../api/server/action/response";
import moment from "moment";
import { nowTimeString } from "../utils/time";
import { APPNAME, APPTASKNAME } from "../services/constants";
import { AirdropType, getAirdropInfoWithAddress } from "../api/server/airdrop/app";
import { AirdropInfoResponseData } from "../api/server/airdrop/response";
import useTask from "../pages/useTask";
import { useWallet } from "@solana/wallet-adapter-react";
import useUpdateUserInfo from "./useUpdateUserInfo";

export interface TaskData {
  XTask: {
    id:number;
    complete:boolean;
  }
  TelegramTask: {
    id:number;
    complete:boolean;
  };
  completeConnectWallet:boolean;
  completeCount:number;
}

const initData:TaskData = {
  XTask: {
    complete:false,
    id:-1,
  },
  TelegramTask: {
    complete:false,
    id:-1,
  },
  completeConnectWallet: false,
  completeCount: 0,
};

const useMyProfile = () => {
  const [pageData, setPageData] = useState<TaskData>(initData);

  useEffect(() => {
    console.log('profile***pageData**',pageData)
  
    return () => {
      
    }
  }, [pageData])
  

  const [errorMsg, setErrorMsg] = useState<string>();

  const userInfoCacheData = useSelector<AppState, userInfoLocalData.Selectors>(
    ({ shared }) =>
      sharedSelectors.local.userInfoLocalData(
        shared.local.userInfoLocalData,
        {}
      )
  );

  useEffect(() => {
    if (!userInfoCacheData?.getData?.uid) return;
    getInviteCode();
    getTaskData();
  }, [userInfoCacheData?.getData?.uid]);

  useEffect(() => {
    if (!userInfoCacheData?.getData?.uid || !userInfoCacheData?.getData?.address) return;
    getAirdropData(userInfoCacheData?.getData.address,'effectiveai');
  }, [userInfoCacheData?.getData]);
  
  const [inviteCode, setInviteCode] = useState("");

  const getInviteCode = async () => {
    const response = await createInviteCode();
    // console.log("@@@@@@@@@@@  getInviteCode-- response ", response)
    if (response?.data?.code === 200) {
      const code = response.data.data.code;
      setInviteCode(code);
    }
  };

 const [airdropAmount, setAirdropAmount] = useState(0)

  const getAirdropData = async (address:string,type:AirdropType) => {
    const response:AirdropInfoResponseData = (await getAirdropInfoWithAddress(address,type))?.data;
    console.log("@@@@@@@@@@@  getAirdropInfoWithAddress-- response ", response)
    if (response?.data?.length > 0) {
      const data = response.data[0]
      setAirdropAmount(data.amount/(10**8));
    }
  };

  

  const getTaskData = useCallback(async () => {
    let count = 0
    const response = await getTask("task-telegram-join");
    console.log("@@@@@@@@@@@getTaskresponse**task-telegram-join**", response.data);
    let TelegramTask:any = undefined;
    if (response.data.code === 200 && response.data.data.length > 0) {
      TelegramTask ={
        complete: response.data.data[0].status === 0,
        id:response.data.data[0].id
      } 
      count = TelegramTask.complete?++count:count;
    }
    console.log("@@@@@@@@@@@getTaskresponse**TelegramTask**", TelegramTask);
    // const response1 = await getTask("task-x-follow");
    // console.log("@@@@@@@@@@@getTaskresponse**task-x-follow**", response1.data);
    const response1 = await getTask('task-x-share');
    console.log("@@@@@@@@@@@getTaskresponse**task-x-share**", response1.data);
    let XTask:any = undefined;
    if (response1.data.code === 200 && response1.data.data.length > 0) {
      XTask = {
        complete: response1.data.data[0].status === 0,
        id:response1.data.data[0].id
      } 
      count = XTask.complete?++count:count;
    }

    console.log("@@@@@@@@@@@getTaskresponse**XTask**", XTask);
    setPageData( p=>{return{
      ...pageData,
      TelegramTask:TelegramTask?TelegramTask:{
        complete:false,
        id:-1
      },
      XTask:XTask?XTask:{
        complete:false,
        id:-1
      },
      completeCount: pageData.completeCount+count,
    }})
  },
  [userInfoCacheData?.getData?.uid],
)

  const sendTaskData = async (event: EventType) => {

    switch(event){
      case "stay":
        return;
        break;
      case "task-telegram-join":
        if(pageData.TelegramTask.id > -1){
          const result= updateTaskData('task-telegram-join',TaskStatus.finish)
          !result&&setErrorMsg("Fail to fetch Task Data");
        return result;
        }
        break;
      case "task-x-follow":
        if(pageData.XTask.id > -1)
        {
          const result= updateTaskData('task-x-follow',TaskStatus.finish)
        !result&&setErrorMsg("Fail to fetch Task Data");
        return result;
        }
        break;
    }
    
    if (!userInfoCacheData?.getData.username) {
      console.error("no username when sendtask");
      setErrorMsg("Unable to get Task Data");
      return false;
    }
    const now = nowTimeString();
    const taskData: TaskRequestData = {
      userName: userInfoCacheData?.getData.username,
      date: now,
      startTime: now,
      endTime: now,
      normalTime: 1,
      app: APPTASKNAME,
      eventType: event,
      notes: "",
    };
    const response = await sendTask(taskData);
    console.log(
      "@@@@@@@@@@@sendTaskData**",
      event,
      response,
      response?.data?.code
    );
    if (response?.data?.code === 200) {
      return true;
    }
    console.log(
      "@@@@@@@@@@@sendTaskData**setErrorMsg"
    );
    setErrorMsg("Fail to fetch Task Data");
    return false;
  };

  const updateTaskData = async (event: EventType,status:TaskStatus) => {
    
    let id =-1;
    if(event ==='task-telegram-join'){
      id = pageData.TelegramTask.id
    }
    else if(event === 'task-x-follow'){
      id = pageData.XTask.id
    }
    console.log(
      "@@@@@@@@@@@updateTaskData**id**",
      id,
    );
    if(id < 0){
      console.error('no id ')
      return;
    }
    const response = await updateTask(id,status);
    console.log(
      "@@@@@@@@@@@updateTaskData**",
      event,
      id,
      response,
      response?.data?.code
    );
    if (response?.data?.code === 200) {
      return true;
    }
    console.log(
      "@@@@@@@@@@@updateTaskData**setErrorMsg"
    );
    return false;
  };

  const cancelTaskData = async (event: EventType) => {
   const result= await updateTaskData(event,TaskStatus.undo)
    !result && setErrorMsg("Fail to unlink");
    return result;
  };


  return { inviteCode, getInviteCode, pageData, sendTaskData,errorMsg,cancelTaskData,airdropAmount };
};

export default useMyProfile;
