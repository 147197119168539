import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeWalletAddress, createInviteCode } from "../api/server/user/app";
import { sharedSelectors } from "../redux";
import { AppState } from "../redux/app-state";
import { userInfoLocalData } from "../redux/local";
import { TaskRequestData, updateTask, getTask, sendTask, TaskStatus } from "../api/server/action/app";
import { EventType } from "../api/server/action/response";
import moment from "moment";
import { nowTimeString } from "../utils/time";
import { APPNAME, APPTASKNAME } from "../services/constants";
import { AirdropType, getAirdropInfoWithAddress } from "../api/server/airdrop/app";
import { AirdropInfoResponseData } from "../api/server/airdrop/response";
import useTask from "../pages/useTask";
import { useWallet } from "@solana/wallet-adapter-react";
import useUpdateUserInfo from "./useUpdateUserInfo";
import { ed25519 } from "@noble/curves/ed25519";
import { verifySignIn } from "@solana/wallet-standard-util";
import { SolanaSignInInput } from "@solana/wallet-standard-features";
import { ResponseData } from "../api/server/response";
import bs58 from "bs58";

interface SignResult{
  message: string;
  hash: string;
}

const useConncectWallet = () => {
  const userInfoCacheData = useSelector<AppState, userInfoLocalData.Selectors>(
    ({ shared }) =>
      sharedSelectors.local.userInfoLocalData(
        shared.local.userInfoLocalData,
        {}
      )
  );


  const [errorMsg, setErrorMsg] = useState("");
  const [bindWalletAddressSuccess, setBindWalletAddressSuccess] =
  useState(false);


  async function onConnectWallet(walletAddress: string) {
    setErrorMsg('')
    const signResult:SignResult |undefined = await onSignMessage();
    console.log("sign****", signResult);
    if(!signResult) return false;
    const uid = userInfoCacheData?.getData?.uid?.toString();
    console.log("onConnectWallet***uid", uid, walletAddress);
    if (!uid || !walletAddress) return false;

    const resp:ResponseData<any> =(await changeWalletAddress(uid,walletAddress,signResult.message,signResult.hash))?.data
    console.log('onConnectWallet***',resp)
    if( resp && resp?.code === 200){
      setBindWalletAddressSuccess(true)
      
      return true
    }
    else{
      resp?.msg && setErrorMsg(resp?.msg)
      return false;
    } 
  }

  async function onDisconnectWallet() {
    setErrorMsg('')
   
    const uid = userInfoCacheData?.getData?.uid?.toString();
    console.log("onDisconnectWallet***uid", uid);
    if (!uid ) return false;

    const resp:ResponseData<any> =(await changeWalletAddress(uid,'','',''))?.data
    console.log('onDisconnectWallet***',resp)
    if( resp && resp?.code === 200){
      setBindWalletAddressSuccess(true)
      
      return true
    }
    else{
      resp?.msg && setErrorMsg(resp?.msg)
      return false;
    } 
  }


  const { signIn, publicKey, signMessage } = useWallet();

  const signInSolana = useCallback(async () => {
    if (!signIn)
      throw new Error("Wallet does not support Sign In With Solana!");

    const input: SolanaSignInInput = {
      domain: window.location.host,
      address: publicKey ? publicKey.toBase58() : undefined,
      statement: "Please sign in.",
    };
    const output = await signIn(input);

    if (!verifySignIn(input, output))
      throw new Error("Sign In verification failed!");

    return false;
  }, []);

  const onSignMessage = useCallback(async () => {
    try {
      if (!publicKey) throw new Error("Wallet not connected!");
      if (!signMessage)
        throw new Error("Wallet does not support message signing!");
      const signMsg = `${APPNAME} wants you to sign in with your Solana account:\n${publicKey.toBase58()}\n\nPlease sign in.`;
      const message = new TextEncoder().encode(signMsg);
      const signature = await signMessage(message);

      if (!ed25519.verify(signature, message, publicKey.toBytes()))
        throw new Error("Message signature invalid!");
      return {
        message: signMsg,
        hash: bs58.encode(signature),
      };
      // notify('success', `Message signature: ${bs58.encode(signature)}`);
    } catch (error: any) {
      // notify('error', `Sign Message failed: ${error?.message}`);
    }
  }, [publicKey, signMessage]);

  return {onConnectWallet,onDisconnectWallet };
};

export default useConncectWallet;
