// ===================== 用户 =====================
export const signUp = "/register";
export const logIn = "/login";
export const changePassword = "/system/user/profile/updatePwd";
export const createInviteCode = "/invit/generate";
export const changeWalletAddress = "/system/user/changeAddress";
export const userInfo = "/getInfo";

export const getEmailVerifyCode = "/getEmailCode";

export const verifyEmailCode = '/checkcode'
// export const resetPassword = '/findMyPwd'

//new
export const isUsernameRegister = "/isUsernameRegistered";
export const isEmailRegister = "/isEmailRegistered";



export const getEmailCode = '/getEmailCode'
export const checkEmailCode = '/checkCode'
export const findMyPwd = 'system/user/profile/findMyPwd'

export const getWebsite = '/websites'
export const getWebsiteWithCatergory = '/websites/searchCategoryWebsites'