import { UserInfoResponseData } from "../../api/server/user/response";
import { AuthInfoData } from "../../redux/presist/auth-local-data";
import { UserInfoLocalData } from "../../redux/local/userinfo-local-data";
import { getUserInfo } from "../../api/server/user/app";


// const initialUserInfoCacheData: UserInfoLocalData = {
//   header_image: undefined,
// lastName: undefined,
// country: undefined,
// city: undefined,
// addressLineOne: undefined,
// bio: undefined,
// taxResidency: undefined,
// uid: undefined,
// firstName: undefined,
// addressLineTwo: undefined,
// nationality: undefined,
// nname: undefined,
// postCode: undefined,
// username: undefined,
// musicToken:undefined,
// nickname:undefined,
// };


const buildUserInfoCacheData = (data: UserInfoResponseData) => {
  const result: UserInfoLocalData = {
   ...data,
   uid:data.userId,
   header_image:data.avatar,
   nickname:data.nickName,
   username:data.userName,
   musicToken:undefined
  }
  return result;
}




const buildAuthInfoCacheData = (data: any) => {

  const result: AuthInfoData =  {...data,canAuth:true,userId: '',refreshToken:''};
  // result.avatar= resUrl+data.avatar;
  return result;
}


const initialAuthInfoCacheData: AuthInfoData = {
  accessToken: undefined,
  tokenType: undefined,
  refreshToken: undefined,
  userId: undefined,
  businessId: undefined,
  oauthId: undefined,
  avatar: undefined,
  authority: undefined,
  username: undefined,
  nickName: undefined,
  expiresIn: undefined,
  canAuth:false,
  sender:'effective-web'
};



export {
  buildUserInfoCacheData,
  buildAuthInfoCacheData,
  initialAuthInfoCacheData, 
};
