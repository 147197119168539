import { ActionType, createReducer, createStandardAction } from 'typesafe-actions';
import { createStructuredSelector } from 'reselect';

export type PersistSettingsLocalData = {
  finishWatchSBTVideo:boolean,
  lastBlindBoxBuyTransaction:string,
  lastPotShowTime:string
};

const initialState: PersistSettingsLocalData = {
  finishWatchSBTVideo:false,
  lastBlindBoxBuyTransaction:'',
  lastPotShowTime:''
};

export type Selectors = {
  getData: PersistSettingsLocalData;
};

const actions = {
  update: createStandardAction('UPDATE_PERSIST_SETTINGS_CACHE_DATA')<
    PersistSettingsLocalData
  >(),
  updateFinishWatchSBTVideo: createStandardAction('UPDATE_PERSIST_WATCH_SBT_SETTINGS_CACHE_DATA')<
    boolean
  >(),
  updateLastBlindBoxBuyTransaction: createStandardAction('UPDATE_PERSIST_BLINDBOX_TRAN_SETTINGS_CACHE_DATA')<
  string
>(),
updateLastPotShowTime: createStandardAction('UPDATE_PERSIST_POT_SHOWTIME_TRAN_SETTINGS_CACHE_DATA')<
string
>(),
  reset: createStandardAction('RESET_PERSIST_SETTINGS_CACHE_DATA')()
};

export type PersistSettingsDataActions = ActionType<typeof actions>;

const reducer = () =>
  createReducer<PersistSettingsLocalData, PersistSettingsDataActions>(
    initialState
  )
    .handleAction(actions.update, (state, { payload }) => ({
      ...state,
      ...payload
    }))
    .handleAction(actions.updateFinishWatchSBTVideo, (state, { payload }) => ({
      ...state,
      finishWatchSBTVideo:payload
    }))
    .handleAction(actions.updateLastBlindBoxBuyTransaction, (state, { payload }) => ({
      ...state,
      lastBlindBoxBuyTransaction:payload
    }))
    .handleAction(actions.updateLastPotShowTime, (state, { payload }) => ({
      ...state,
      lastPotShowTime:payload
    }))
    .handleAction(actions.reset, () => ({ ...initialState }

    ));

const select = createStructuredSelector<PersistSettingsLocalData, {}, Selectors>({
  getData: state => state,
});

export { actions, reducer, select };
