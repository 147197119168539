const maxLevel = 6

const LevelData = [
  { level: 1, levelCount: 1 },
  { level: 2, levelCount: 3 },
  { level: 3, levelCount: 10 },
  { level: 4, levelCount: 20 },
  { level: 5, levelCount: 100 },
  { level: 6, levelCount: 200 },
]


const calculateNextInvite = (level: number, inviteCount: number) => {

  if (level === 0) {
    return '1'
  }
  const nextLevel = level + 1
  if (nextLevel > maxLevel) {
    return '0'
  }
  const levelData = LevelData.find(item => item.level === nextLevel)
  console.log('@@@@@@@@@@ calculateNextInvite ---- levelData:', levelData)
  if (levelData === null || levelData === undefined) {
    return '--'
  }
  const needInvite = levelData.levelCount - inviteCount
  return needInvite
}



export {
  maxLevel,
  LevelData,
  calculateNextInvite
}