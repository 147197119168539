import React, { useEffect, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import AppLogo from '../images/App-logo.png'
import { Openup as OpenupIcon } from '../svg/components'
import { MYPROFILE, WELCOME,HOMEPAGE } from '../router/constants'
import useUser from './user-hook'
import { omitMiddleAddress } from '../utils/ethUtils'
import { domain } from '../utils/env'
import Button from './Button.react'
import { CAN_USER_INVITE, forceWebDownload, isDev, pluginDownloadUrl } from '../services/constants'
import ImgDefaultAvator from '../images/icon/default_avator.png'

function Header() {
  // const buttonRef = useRef(null);
  const { nickName, userIcon, walletAddress,isLogined } = useUser();
  const navigate=useNavigate()
  // const isDev = process.env.REACT_APP_ENV === 'dev'
  return <div className='header-gradient relative flex flex-row items-center justify-between h-[102px] px-12'>
    <div>
      <Link to={HOMEPAGE}>
        <img className='h-[45px] object-contain' src={AppLogo} />
      </Link>
    </div>
    <div className='flex flex-row items-center space-x-2'>
      <Button
      //  useRef={buttonRef}
        onClick={(e) => {
          // const forceWebDownload = true;
          // const { x, y } = buttonRef.current.getBoundingClientRect();
          // const eventX = e.clientX - x;
          // const eventY = e.clientY - y;
          // console.log('click**',e,'x**',eventX,'y**',eventY)

          const url = pluginDownloadUrl
          // const url = 'https://chromewebstore.google.com/detail/chronicle-by-effective-ai/bnooefggdiclpcjnfoonnpflfhlnpnpp?hl=zh-CN&utm_source=ext_sidebar'
          window.open(url)
        }}>Download Chronicle</Button>
       {isLogined &&CAN_USER_INVITE && <Button
        onClick={() => {
          navigate(MYPROFILE)
        }}>Refer friends</Button>}
      {!nickName && (<Link to={WELCOME}>
        <button className={`flex px-6 py-4 justify-center items-center h-12 bg-white text-black hover:bg-[#BBBFCC] active:bg-[#BBBFCC] rounded-full`}>
          <span className='font-sans text-lg font-medium'>Log in</span>
          <span className='inline-flex text-[8px] ml-2'>
            <OpenupIcon />
          </span>
        </button>
      </Link>) ||
        (<div className='flex flex-row items-center'>
          <img className='ml-1 size-8 bg-[#3CEC82] rounded-full' 
          // src={domain + userIcon} 
            src={ImgDefaultAvator}
          />
          <div className='font-sans ml-3 mr-[5px] text-base text-[#E2F2FF]'>{!!walletAddress?omitMiddleAddress(walletAddress) : nickName} </div>
        </div>)}
    </div>
  </div>
}

export default Header