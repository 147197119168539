import React from 'react'

import IntroCard from '../components/IntroCard.react'
import Input from '../components/Input.react'
import Button from '../components/Button.react'
import Error from '../components/Error.react'
import useSignup from './useSignup';

function Signup({ open, address }) {
  const { onSignupClick, btnAvailable: signUpBtnAvailable, formData, setUsername, setEmail, setPasssword, setConfirmPasssword, setInviteCode, errorMsg } = useSignup();
  // console.log('signUpBtnAvailable***',signUpBtnAvailable)
  return <div className="relative flex flex-1 w-full h-full bg-black ">
    <div className='relative flex flex-1 flex-row'>
      <IntroCard />
      <div className='relative flex flex-1 justify-center'>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            e.stopPropagation()
            return false
          }}
          className='flex flex-col justify-center w-[310px] space-y-4'>
          <div className='font-sans text-5xl uppercase font-medium'>Sign up</div>
          <Input
            title={'Your Username '}
            value={formData.username.value}
            onChange={(e) => {
              const value = e.target.value
              setUsername(value)
            }} />
          <Input
            title={'Email'}
            value={formData.email.value}
            onChange={(e) => {
              const value = e.target.value
              setEmail(value)
            }} />
          <Input
            title={'Password (minimum 10 characters)'}
            value={formData.password.value} type='password'
            error={(formData.password.type === 'empty' || formData.password.type === 'tooshort') ?'Must contains 10 characters':
            ((formData.password.type === 'toolong') ?'Must less than 20 characters':((formData.password.type === 'illegal') ?'Illegal characters':''))}
            onChange={(e) => {
              const value = e.target.value
              setPasssword(value)
            }} />
          <Input
            title={'Confirm Password'}
            value={formData.confirmpassword.value}
            type='password'
            onChange={(e) => {
              const value = e.target.value
              setConfirmPasssword(value)
            }} />
          <Input
            title={'Referral code'}
            value={formData.inviteCode.value}
            onChange={(e) => {
              const value = e.target.value
              setInviteCode(value)
            }} />
          {/* <Button>
            <div className={`flex flex-row items-center `}  onClick={()=>{}}>
              <span>Connect Wallet</span>
              <span className='ml-2.5 px-1 py-0.5 bg-[#00F3FF] text-[9px] leading-[10px] text-black rounded-xl'>Solana</span>
            </div>
          </Button> */}
          <Button
            gradient
            className={`${!!signUpBtnAvailable ? 'cursor-pointer' : 'cursor-not-allowed'}`}
            onClick={() => { if (signUpBtnAvailable) onSignupClick() }}
          >Continue</Button>
        </form>
        {errorMsg && <Error>{errorMsg}</Error>}
      </div>
    </div>
  </div>
}

export default Signup